@import "support";

.preapprove_pgBlk {
  display: block;
  margin-top: 25px;

  .preApprove_breadcrumb {
    display: block;
    background: $color-white;
    margin: 0px;
    padding: 18px 17px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    margin-bottom: 5px;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-26;
    color: $color-blue-dark;
  }

  ul.preApprove_IIcol_285by100pc {
    display: block;
    background: $color-white;
    margin: 0px;
    padding: 0px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

    li.preApprove_IIcol_285by100pc {
      display: inline-block;
      vertical-align: top;
      padding: 20px;
      width: 285px;
    }

    li.preApprove_IIcol_285by100pc:last-child {
      width: calc(100% - 285px);
      width: -webkit-calc(100% - 285px);
      border-left: 10px solid $color-offwhite;
      padding: 0px;
      margin-left: -1px;
      padding-bottom: 15px;
    }

    @media ($smaller-than: $breakpoint-899) {
      li.preApprove_IIcol_285by100pc:first-child {
        display: none;
      }

      li.preApprove_IIcol_285by100pc:last-child {
        width: 100%;
        border: none;
        margin: 0px;
        padding-bottom: 5px;
      }
    }
  }

  .breadcrumbHead {
    display: block;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-18;
    color: $color-blue-navi-dark;
    margin-bottom: 10px;
  }

  ul.breadcrumb {
    display: block;

    li.breadcrumb {
      display: inline-block;
      vertical-align: top;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-15;
      color: $color-blackbrown-light;
    }

    li.breadcrumb a {
      text-decoration: none;
      color: $color-blackbrown-light;
    }

    li.breadcrumb a:hover {
      cursor: pointer;
      color: $color-blackbrown;
    }

    li.breadcrumb:nth-child(odd) {
      width: 24px;
      aspect-ratio: 1/1;
      margin: 10px 3px 7px 0px;
      background: $color-blue-lightest;
      background-image: $bgTexure;
      border-radius: 50%;
      text-align: center;
      line-height: 24px;
    }

    li.breadcrumb:nth-child(even) {
      width: calc(100% - 37px);
      width: -webkit-calc(100% - 37px);
      margin: 14px 0px 0px 10px;
    }

    .expCol {
      float: right;
      font-family: $font-arial;
      font-size: $fontsize-20;
      background: $color-offwhitelight;
      background-image: $bgTexure;
      height: 20px;
      aspect-ratio: 1/1;
      line-height: 20px;
      text-align: center;
    }

    .childBlock {
      display: none;
    }

    ul.sub_breadcrumb {
      display: block;

      li.sub_breadcrumb {
        display: block;
        background-image: $bgTexure;
        padding: 8px 7px;
        font-family: $font-arial;
        font-size: $fontsize-14;
        color: $color-lightblack;

        border-bottom: 1px dashed $color-offwhite;
      }

      li.sub_breadcrumb:first-child {
        margin-top: 7px;
        border-top: 1px dashed $color-offwhite;
      }

      li.sub_breadcrumb:last-child {
        margin-bottom: 10px;
      }
    }
  }

  ul.step_count {
    display: block;
    margin-bottom: 2px;
    margin-right: -1px;
    padding: 0 25px;
    border-bottom: 10px solid $color-offwhite;

    li.stepHead,
    li.stepHead_100pc {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 105px);
      width: -webkit-calc(100% - 105px);
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-24;
      color: $color-blue-navi-dark;
      padding: 25px 0 17px 0px;
    }

    li.stepHead_100pc {
      display: block;
      width: 100%;
    }

    li.countBlk {
      display: inline-block;
      vertical-align: top;
      width: 100px;
      padding: 15px 0 15px 0px;

      .countNo {
        display: block;
        float: right;
        height: 50px;
        aspect-ratio: 1/1;
        color: $color-blue-navi;
        background: $color-offwhite;
        background-image: $bgTexure;
        border-radius: 50%;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-24;
        text-align: center;
        line-height: 50px;
        -moz-box-shadow: inset 0 0 5px $color-offblack;
        -webkit-box-shadow: inset 0 0 5px $color-offblack;
        box-shadow: inset 0 0 5px $color-offblack;
      }
    }

    @media ($smaller-than: $breakpoint-999) {
      li.stepHead,
      li.stepHead_100pc {
        display: block;
        line-height: normal;
        padding: 17px 0;
        width: 100%;
      }

      li.countBlk {
        display: none;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      li.stepHead,
      li.stepHead_100pc {
        font-size: $fontsize-22;
        padding: 10px 0;
      }
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    ul.step_count {
      padding: 5px 15px;
    }
  }

  .purchase {
    display: block;
    min-height: 300px;
    padding: 20px 25px 10px 25px;

    ::-webkit-input-placeholder {
      color: $color-lightblack;
    }

    :-moz-placeholder {
      color: $color-lightblack;
    }

    ::-moz-placeholder {
      color: $color-lightblack;
    }

    :-ms-input-placeholder {
      color: $color-lightblack;
    }

    select:required:invalid {
      color: $color-lightblack;
    }

    option {
      color: $color-lightblack;
    }

    select::-ms-expand {
      display: none;
    }

    select {
      width: 100%;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-blackbrown-light;
      border: 1px solid $color-blue-lighter;
      border-radius: 5px;
      outline: 0;
      padding: 15px 15px;
      background: $selectarrow_blackIcon_SVG;
      background-position: right 4% center;
      background-color: $color-white;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    select:hover {
      cursor: pointer;
      background: $selectarrow_blueIcon_SVG;
      background-position: right 4% center;
      background-color: $color-white;
    }

    input[type="text"],
    input[type="password"] {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-blackbrown-light;
      width: 100%;
      padding: 15px 15px;
      border: 1px solid $color-blue-lighter;
      border-radius: 5px;
      background: $color-white;
      outline: 0;
    }

    @media ($smaller-than: $breakpoint-699) {
      input[type="text"],
      input[type="password"],
      select {
        font-size: $fontsize-16;
        padding: 8px 8px;
      }
    }

    .input-icon {
      position: relative;
    }

    .input-icon > i {
      position: absolute;
      display: block;
      transform: translate(0, -50%);
      top: 50%;
      pointer-events: none;
      width: 25px;
      text-align: center;
      font-style: normal;
    }

    .input-icon > input {
      padding-left: 30px;
      padding-right: 0;
    }

    .input-icon-right > i {
      right: 0;
      color: $color-blackbrown-light;
    }

    .input-icon-right > input {
      padding-left: 0;
      padding-right: 25px;
      text-align: right;
    }

    ul.II_col {
      display: block;
      margin: 0px;
      padding: 0px;

      li.II_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 13px);
        width: -webkit-calc(100%/2 - 13px);
        margin-right: 20px;
      }

      li.II_col:last-child {
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.II_col {
          display: block;
          width: 100%;
          margin: 0 0 15px 0;
        }
      }
    }

    ul.III_col {
      display: block;
      margin: 0px;
      padding: 0px;

      li.III_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 3 - 17px);
        width: -webkit-calc(100%/3 - 17px);
        margin-right: 20px;
      }

      li.III_col:last-child {
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.III_col {
          display: block;
          width: 100%;
          margin: 0 0 15px 0;
        }
      }
    }

    ul.II_col3by2 {
      display: block;
      margin: 0px;
      padding: 0px;

      li.II_col3by2 {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 3 - 17px);
        width: -webkit-calc(100%/3 - 17px);
        margin-right: 20px;
      }

      li.II_col3by2:last-child {
        width: calc(100% - (100% / 3 - -10px));
        width: -webkit-calc(100% - (100%/3 - -10px));
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.II_col3by2,
        li.II_col3by2:last-child {
          display: block;
          width: 100%;
          margin: 0px 0px 5px 0px;
        }
      }
    }

    ul.II_col_80by20 {
      display: block;
      margin: 0px;
      padding: 0px;

      li.II_col_80by20 {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 80px);
        width: -webkit-calc(100% - 80px);
        margin: 0 20px 15px 0;
      }

      li.II_col_80by20:nth-child(even) {
        width: 55px;
        margin: 0px;
        padding-top: 40px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.II_col_80by20 {
          display: block;
          width: 100%;
          margin: 0px;
        }

        li.II_col_80by20:nth-child(even) {
          padding-top: 0px;
          margin-bottom: 15px;
        }
      }
    }

    ul.III_col_income {
      display: block;
      margin: 0px;
      padding: 0px;

      li.III_col_income_head {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 50px);
        width: -webkit-calc(100%/2 - 50px);
        margin-right: 20px;
      }

      li.III_col_income_head:last-child {
        margin-right: 0px;
        width: 50px;
      }

      li.III_col_income {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 50px);
        width: -webkit-calc(100%/2 - 50px);
        margin-right: 20px;
        margin-bottom: 20px;
      }

      li.III_col_income:last-child {
        margin-right: 0px;
        width: 50px;
      }
    }

    ul.II_col_by150 {
      display: block;
      margin: 0px;
      padding: 0px;

      li.II_col_by150 {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 165px);
        width: -webkit-calc(100% - 165px);
        margin-right: 10px;
      }

      li.II_col_by150:last-child {
        width: 150px;
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.II_col_by150 {
          display: block;
          width: 100%;
          margin: 0px;
        }
      }
    }

    .button_adddelete {
      display: block;
      width: 45px;
      aspect-ratio: 1/1;
      border: 0px solid #2aa3ef;
      border-radius: 5px;
      font-family: $font-arial;
      font-size: $fontsize-22;
      color: $color-white;
      outline: none;
      background: $color-blue-light;
      text-align: center;
    }

    .button_adddelete:hover {
      background: $color-blue;
      cursor: pointer;
    }

    @media ($smaller-than: $breakpoint-699) {
      .button_adddelete {
        width: 35px;
        font-size: $fontsize-20;
      }
    }

    .button_verify {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 8px);
      width: -webkit-calc(100%/2 - 8px);
      margin-right: 8px;
      padding: 15px 0px;
      border: 0px;
      border-radius: 5px;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-16;
      color: $color-white;
      outline: none;
      background: $color-blue-light;
      text-align: center;
    }

    .button_verify:hover {
      background: $color-blue;
      cursor: pointer;
    }

    .button_verify[disabled],
    .button_verify[disabled]:hover,
    .button_verify.disabled,
    .button_verify.disabled:hover {
      background: $color-lightblack;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }

    .pur_Q,
    .pur_Q_error {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-18;
      color: $color-blackbrown;
      margin: 0 0 8px 0;
    }

    .pur_Q_error {
      color: $color-red;
    }

    @media ($smaller-than: $breakpoint-699) {
      .pur_Q {
        font-size: $fontsize-20;
      }
    }

    ul.pur_A {
      display: block;
      padding: 0;
      margin-bottom: 15px;

      li {
        display: inline-block;
        margin-bottom: 15px;
      }

      li a {
        display: inline-block;
        font-family: $font-arial;
        font-size: $fontsize-16;
        color: $color-blackbrown;
        background: $color-white;
        text-decoration: none;
        margin: 0 10px 0 0;
        padding: 12px 15px;
        border: 1px solid $color-blue-lighter;
        border-radius: 5px;
        transition: background 0.5s, color 0.5s;
        -webkit-transition: background 0.5s, color 0.5s;
      }

      a:hover {
        position: relative;
        background-image: $bgTexure;
        color: $color-black;
      }

      a.current {
        position: relative;
        color: $color-white;
        background: $color-blue-light;
        background-image: $bgTexure;
        border-color: $color-blue-light;
        cursor: default;
      }

      @media ($smaller-than: $breakpoint-699) {
        li {
          margin-bottom: 10px;
        }

        li a {
          padding: 8px 10px;
          margin-right: 5px;
          font-size: $fontsize-16;
        }
      }
    }

    .signupChkBoxLabel {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-15;
      font-weight: normal;
      margin: 25px 0px;

      a {
        color: $color-blue;
        text-decoration: none;
      }

      a:hover {
        color: $color-blackbrown-light;
        cursor: pointer;
      }
    }

    .haveAcc {
      font-family: $font-arial;
      font-size: $fontsize-16;
      font-weight: bold;
      color: $color-blackbrown-light;
      margin: 25px 0px;
    }

    .haveAcc a {
      color: $color-blue;
      text-decoration: none;
    }

    .haveAcc a:hover {
      color: $color-blackbrown-light;
      cursor: pointer;
    }

    input[type="checkbox"] {
      display: none;
      cursor: pointer;
    }

    input[type="checkbox"]:not([disabled]) + label {
      cursor: pointer;
    }

    input[type="checkbox"] + label:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: middle;
      margin: 0 5px 3px 0;
      border: 1px solid $color-blue-lighter;
      border-radius: 3px;
      background: $color-white;
    }

    input[type="checkbox"]:checked + label:before {
      content: "\2714";
      color: $color-blackbrown;
      font: $fontsize-14 $font-arial;
      text-align: center;
      line-height: 14px;
    }

    input[type="radio"] {
      display: none;
      cursor: pointer;
    }

    input[type="radio"]:not([disabled]) + label {
      cursor: pointer;
    }

    input[type="radio"] + label:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: middle;
      border-radius: 50%;
      margin: 0 5px 3px 0;
      border: 1px solid $color-blue-lighter;
      background: $color-white;
    }

    input[type="radio"]:checked + label:before {
      content: "\2022";
      color: $color-blue;
      font: $fontsize-32 $font-arial;
      text-align: center;
      line-height: 14px;
    }

    .checkboxLabel {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-14;
      color: $color-lightblack;

      a {
        text-decoration: none;
        font-weight: bold;
        color: $color-lightblack;
        border-bottom: 1px dashed $color-lightblack;
      }

      a:hover {
        cursor: pointer;
        color: $color-blackbrown-light;
      }
    }

    .su_method {
      display: block;
      padding: 0 0 5px 0;
      margin-bottom: 20px;
      border-bottom: 2px solid $color-offblack;

      .method_head {
        display: block;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-18;
        color: $color-blue;
        margin-bottom: 15px;
      }

      .twitterLogo,
      .facebookLogo,
      .googleLogo {
        display: inline-block;
        vertical-align: top;
        height: 40px;
        width: 130px;
        margin-right: 10px;
        border-radius: 8px;
      }

      .twitterLogo {
        background: $twitter_SVG;
        background-position: center center;
      }

      .facebookLogo {
        background: $facebook_SVG;
        background-position: center center;
      }

      .googleLogo {
        background: $google_SVG;
        background-position: center center;
      }
    }

    .su_emailmethod {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-18;
      color: $color-blue;
      margin-bottom: 20px;
    }

    .noteText {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-lightblack;
    }

    #signInOTP {
      display: none;
    }

    .button_preApprove_blk {
      display: block;
      margin: 0px 0px 0px 0px;
      padding: 15px 0px 10px 0px;
      border-top: 5px solid $color-offwhite;

      .button_steps {
        display: inline-block;
        vertical-align: middle;
        padding: 0px 25px;
        height: 45px;
        margin-right: 7px;
        border: 0px solid $color-blue;
        border-radius: 5px;
        font-family: $font-arial;
        font-size: $fontsize-18;
        color: $color-white;
        outline: none;
        background: $color-blue;
        text-align: center;
      }

      .button_steps:hover {
        background: $color-blue-navi;
        cursor: pointer;
      }

      .button_steps[disabled],
      .button_steps[disabled]:hover,
      .button_steps.disabled,
      .button_steps.disabled:hover {
        background: $color-lightblack;
        color: $color-offblack;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-disable;
      }

      @media (max-width: 699px) {
        .button_steps {
          padding: 0px 20px;
          height: 40px;
          font-size: $fontsize-17;
        }
      }
    }

    ul.button_successError_blk {
      display: block;
      margin: 0px 0px 10px 0px;
      padding: 15px 0px 15px 0px;
      border-top: 5px solid $color-offwhite;

      li.button_steps_label {
        display: inline-block;
        vertical-align: top;
        width: 440px;
        margin-right: 2px;

        .button_steps {
          display: inline-block;
          vertical-align: middle;
          padding: 0px 25px;
          height: 45px;
          margin-right: 2px;
          border: 0px solid $color-blue;
          border-radius: 5px;
          font-family: $font-arial;
          font-size: $fontsize-18;
          color: $color-white;
          outline: none;
          background: $color-blue;
          text-align: center;
        }

        .button_steps:hover {
          background: $color-blue-navi;
          cursor: pointer;
        }

        .button_steps[disabled],
        .button_steps[disabled]:hover,
        .button_steps.disabled,
        .button_steps.disabled:hover {
          background: $color-lightblack;
          color: $color-offblack;
          cursor: default;
          text-shadow: none;
          position: static;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          border: 0px dotted $color-disable;
        }

        @media (max-width: 699px) {
          .button_steps {
            padding: 0px 15px;
            height: 35px;
            font-size: $fontsize-16;
          }
        }
      }

      li.successError_steps {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 450px);
        width: -webkit-calc(100% - 450px);
      }

      @media (max-width: 699px) {
        li.button_steps_label {
          width: 325px;
        }

        li.successError_steps {
          width: calc(100% - 335px);
          width: -webkit-calc(100% - 335px);
        }
      }

      @media (max-width: 599px) {
        li.button_steps_label {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        li.successError_steps {
          display: block;
          width: 100%;
        }
      }
    }

    .fico_head {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-24;
      color: $color-blue;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 699px) {
    .purchase {
      padding: 15px;
    }
  }
}

/*Approval Letter Styles
------------------*/

ul.approveLetter_blk {
  display: block;
  background: $color-white;
  margin: 0px;
  padding: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  li.approveLetter_blk {
    display: inline-block;
    vertical-align: top;
    width: calc(85% / 2);
    width: -webkit-calc(85%/2);
    padding: 25px 25px 25px 45px;
  }

  li.approveLetter_blk:last-child {
    width: calc(115% / 2);
    width: -webkit-calc(115%/2);
    margin-right: 0px;
    min-height: 400px;
    border-left: 10px solid $color-offwhite;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.approveLetter_blk,
    li.approveLetter_blk:last-child {
      display: block;
      margin: 0px;
      padding: 0px 0px 0px 25px;
      width: 100%;
      border: none;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
    }

    li.approveLetter_blk:last-child {
      padding: 20px;
      border-top: 0px solid $color-offwhite;
    }
  }

  .approveMainText {
    display: block;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-26;
    color: $color-blue-dark;
    padding: 20px 20px 5px 0px;
  }

  .approveMainTextAmount {
    display: block;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-45;
    color: $color-success;
    padding: 0px 20px 20px 0px;
  }

  .approveSecondText {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    color: $color-blue-dark;
    padding: 0px 20px 30px 0px;
  }

  @media ($smaller-than: $breakpoint-599) {
    .approveMainText {
      font-size: $fontsize-24;
    }

    .approveMainTextAmount {
      font-size: $fontsize-34;
      padding-bottom: 10px;
    }

    .approveSecondText {
      font-size: $fontsize-16;
      padding: 0px 20px 20px 0px;
    }
  }

  .approveButtonBlk {
    display: block;
    // padding-left: 20px;
    margin-bottom: 20px;

    .button_approve {
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      padding: 12px 16px;
      border-radius: 7px;
      border: 1px solid $color-blue;
      font-family: $font-arial;
      font-size: $fontsize-16;
      color: $color-white;
      background: $color-blue;
      background-image: $bgTexure;
      text-align: center;
      outline: none;
    }

    .button_approve:hover {
      background: $color-blue-dark;
      cursor: pointer;
    }

    @media ($smaller-than: $breakpoint-599) {
      .button_approve {
        margin-right: 5px;
        padding: 6px 10px;
        border-radius: 5px;
        font-size: $fontsize-15;
      }
    }
  }

  @media ($smaller-than: $breakpoint-1023) {
    .approveButtonBlk {
      padding-bottom: 20px;
    }
  }

  .approvalLetter {
    display: block;

    .approvalcoLogo {
      display: block;
      height: 35px;
      width: auto;
      // background: $i3logo_fintech_SVG;
      // background-position: left;
    }

    .approvalcoAdd {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-13;
      color: $color-blackbrown;
      margin: 5px 0 25px 0;
    }

    ul.letterHeadNMLS_blk {
      display: block;
      margin-bottom: 20px;
      padding-bottom: 3px;
      border-bottom: 4px solid $color-blue;

      li.letterHeadNMLS_blk {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 3px);
        width: -webkit-calc(100%/2 - 3px);
      }

      li.letterHeadNMLS_blk:last-child {
        text-align: right;
        vertical-align: bottom;
      }

      .approvalLetterHead,
      .approvalLetterHeadPre {
        display: block;
        font-family: $font-arial;
        font-size: $fontsize-18;
        color: $color-blue-navi;
        text-transform: uppercase;
      }

      .approvalLetterHeadPre {
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-28;
        color: $color-blue-navi-dark;
      }

      .NMLSTxt {
        display: block;
        font-family: $font-arial;
        font-size: $fontsize-12;
        color: $color-blue;
        text-transform: uppercase;
        padding-bottom: 5px;
      }

      @media ($smaller-than: $breakpoint-599) {
        .approvalLetterHead {
          font-size: $fontsize-15;
        }

        .approvalLetterHeadPre {
          font-size: $fontsize-22;
        }
      }
    }

    .letterDate {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-blackbrown;
      margin-bottom: 20px;
    }

    .letterText,
    .letterTextB {
      display: block;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-blackbrown;
      margin-bottom: 5px;
      text-align: justify;
    }

    .letterTextB {
      font-family: $font-arial;
      font-weight: bold;
    }

    ul.letterBullet {
      display: block;
      list-style: square;
      margin: 15px 0px 15px 15px;
    }
  }
}

@media ($smaller-than: $breakpoint-1023) {
  ul.approveLetter_blk {
    box-shadow: none;
  }
}

.nonApproveLetter_blk {
  display: block;
  background: $color-white;
  margin: 0px;
  padding: 25px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  .nonapprovalBoldText,
  .nonapprovalText {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-20;
    color: $color-blue-dark;
    margin-bottom: 10px;
  }
  .nonapprovalText {
    font-family: $font-agaramond;
    margin-bottom: 5px;
  }
}

/*Saved & Preapproval Letter Styles
......................*/

.savedApprovalLetterBlk {
  display: block;
  background: $color-white;
  margin: 0px;
  padding: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  /*Saved Forms*/
  .savedApproval_head,
  .savedApproval {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      margin: 0px;
      padding: 0px;
      border-top: 1px solid $color-white;
      border-bottom: 1px solid $color-offwhite;
      background-image: $bgTexure;

      td {
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-17;
        color: $color-black;
        height: 40px;
        line-height: 40px;
        padding: 0 5px;
      }

      td:first-child {
        width: auto;
        padding-left: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      td:last-child {
        width: 180px;
      }
    }
  }

  .savedApproval {
    table {
      background-image: none;

      td {
        vertical-align: top;
        font-weight: normal;
        color: $color-blackbrown;
        line-height: 38px;
        height: 38px;
        border-bottom: 1px solid $color-offwhite;
      }

      td a {
        color: $color-blue;
        text-decoration: none;
      }

      td a:hover {
        color: $color-blackbrown-light;
      }

      tr:last-child td {
        border-bottom: 0px;
      }
    }
  }

  /*Approval Letter*/
  .approvalList_head,
  .approvalList {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      margin: 0px;
      padding: 0px;
      border-top: 1px solid $color-white;
      border-bottom: 1px solid $color-offwhite;
      background-image: $bgTexure;

      td {
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-17;
        color: $color-black;
        height: 40px;
        line-height: 40px;
        padding: 0 5px;
      }

      td:first-child {
        width: auto;
        padding-left: 20px;
      }

      td:nth-child(2) {
        width: 180px;
      }

      td:last-child {
        width: 200px;
      }
    }
  }

  .approvalList {
    table {
      background-image: none;

      td {
        vertical-align: top;
        font-weight: normal;
        color: $color-blackbrown;
        line-height: 38px;
        height: 38px;
        border-bottom: 1px solid $color-offwhite;
      }

      td a {
        color: $color-blue;
        text-decoration: none;
      }

      td a:hover {
        color: $color-blackbrown-light;
      }

      tr:last-child td {
        border-bottom: 0px;
      }
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    .approvalList_head,
    .approvalList {
      table {
        td:last-child {
          display: none;
        }
      }
    }
  }
}
