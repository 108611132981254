@import "support";

.form1003_pgBlk {
  display: block;
  margin: 25px 0px;
  padding: 20px 25px 30px 25px;
  background: $color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);

  ul.formHeadStageBlk {
    display: block;
    margin: 0 0 15px 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid $color-offwhite-dark;

    li.formHeadStageBlk {
      display: inline-block;
      vertical-align: top;
    }

    li.formHeadStageBlk:first-child {
      width: calc(100% - 200px);
      width: -webkit-calc(100% - 200px);
    }

    li.formHeadStageBlk:last-child {
      width: 200px;
    }

    li.formHeadStageBlk:first-child {
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-30;
      color: $color-blue-navi-dark;
    }

    @media ($smaller-than: $breakpoint-599) {
      li.formHeadStageBlk:first-child {
        display: block;
        width: 100%;
        text-align: center;
        font-size: $fontsize-24;
      }

      li.formHeadStageBlk:last-child {
        display: none;
      }
    }
  }

  ul.formStage {
    float: right;
    width: 180px;
    margin: 0 auto;
    padding: 0;

    .stageHead {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-14;
      color: $color-blackbrown-light;
      margin-bottom: 4px;
    }

    li.step_5,
    li.step_6,
    li.step_7 {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 5 - 5px);
      width: -webkit-calc(100% / 5 - 5px);
      background: $color-blue-bg-dark;
      height: 10px;
      margin-right: 6px;
      border-radius: 5px;
    }

    li.step_6 {
      width: calc(100% / 6 - 5px);
      width: -webkit-calc(100% / 6 - 5px);
    }

    li.step_7 {
      width: calc(100% / 7 - 6px);
      width: -webkit-calc(100% / 7 - 6px);
    }

    li.step_5:last-child,
    li.step_6:last-child,
    li.step_7:last-child {
      margin-right: 0px;
    }

    li.step_5.current,
    li.step_6.current,
    li.step_7.current {
      background: $color-blackbrown-light;
    }
  }

  ul.appFormNavi {
    display: block;
    margin-bottom: 25px;

    li.appFormNavi_5step {
      display: inline;
      margin-right: 10px;
    }

    li.appFormNavi_6step {
      display: inline;
      margin-right: 6.15px;
    }

    li.appFormNavi_7step {
      display: inline;
      margin-right: 5px;
    }

    li.appFormNavi_5step:last-child,
    li.appFormNavi_6step:last-child,
    li.appFormNavi_7step:last-child {
      margin-right: 0px;
    }

    li.appFormNavi_5step a,
    li.appFormNavi_6step a,
    li.appFormNavi_7step a {
      display: inline-block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-15;
      color: $color-white;
      text-decoration: none;
      height: 37px;
      line-height: 37px;
      padding: 0px 37px 0px 0px;
      border-radius: 6px;
      background: $color-blue-navi-form;
      transition-property: color;
      transition-duration: 0.5s;
      -webkit-transition: 0s;
      transition: 0.5s;
      transition-delay: 0.1s;
    }

    li.appFormNavi_6step a {
      padding: 0px 37px 0px 0px;
    }

    li.appFormNavi_7step a {
      padding: 0px 12.5px 0px 0px;
    }

    li.appFormNavi_5step a:hover,
    li.appFormNavi_6step a:hover,
    li.appFormNavi_7step a:hover {
      color: $color-offwhite;
    }

    li.appFormNavi_5step a.current,
    li.appFormNavi_6step a.current,
    li.appFormNavi_7step a.current {
      position: relative;
      color: $color-white;
      background: $color-blackbrown-light;
      background-image: $bgTexure;
    }

    li.appFormNavi_5step a.current:after,
    li.appFormNavi_6step a.current:after,
    li.appFormNavi_7step a.current:after {
      content: " ";
      position: absolute;
      height: 0;
      width: 0;
      border: 8px solid transparent;
      border-bottom-color: $color-white;
      top: 23px;
      left: 50%;
    }

    li.appFormNavi_5step a.passed {
      position: relative;
      color: $color-white;
      background: $color-greenlight;
      background-image: $bgTexure;
    }

    .naviTxt {
      display: inline-block;
    }

    .navinumber {
      display: inline-block;
      vertical-align: top;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-16;
      color: $color-white;
      text-align: center;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: $color-blue-logo;
      margin-right: 10px;
      width: 35px;
      height: 37px;
      line-height: 37px;
    }

    .navinumber.active {
      background: $color-blackbrown;
      border-right: 0.5px solid $color-offwhite;
    }

    .navinumber.passed {
      background: $color-greendark;
      border-right: 0.5px solid $color-offwhite;
    }

    @media ($smaller-than: $breakpoint-1200) {
      .navinumber {
        display: none;
      }

      li.appFormNavi_5step a.current:after,
      li.appFormNavi_6step a.current:after,
      li.appFormNavi_7step a.current:after {
        display: none;
      }

      li.appFormNavi_5step a {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 5 - 8px);
        width: -webkit-calc(100% / 5 - 8px);
        text-align: center;
        padding: 0px;
      }

      li.appFormNavi_6step a {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 6 - 6px);
        width: -webkit-calc(100% / 6 - 6px);
        text-align: center;
        padding: 0px;
      }

      li.appFormNavi_7step a {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 7 - 5.15px);
        width: -webkit-calc(100% / 7 - 5px);
        text-align: center;
        padding: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-1023) {
      .naviTxt {
        display: none;
      }

      .navinumber {
        display: inline-block;
        margin-right: 0px;
        background: transparent;
      }

      .navinumber.active,
      .navinumber.passed {
        background: transparent;
        border-right: 0px;
      }
    }
  }

  .pageLabelbelow1023 {
    display: none;
    text-align: center;
    font-family: $font-arial;
    font-size: $fontsize-16;
    padding: 8px 5px;
    font-weight: bold;
    color: $color-white;
    background: $color-blackbrown-light;
    background-image: $bgTexure;
    color: $color-white;
    border-radius: 5px;
    margin: -10px 0px 15px 0px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    .pageLabelbelow1023 {
      display: block;
    }
  }

  ul.selectBorrowerBlk {
    display: block;
    margin: 0px 0px 25px 0px;
    padding: 12px;
    border-radius: 5px;
    background: $color-blue-form-bg-dark;
    background-image: $bgTexure;

    li.selectBorrowerBlk {
      display: inline-block;
      vertical-align: top;
    }

    li.selectBorrowerBlk:first-child {
      width: 250px;
      margin-right: 10px;
    }

    li.selectBorrowerBlk:last-child {
      width: calc(100% - 260px);
      width: -webkit-calc(100% - 260px);
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-14;
      color: $color-blackbrown-light;
      line-height: 40px;
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.selectBorrowerBlk:last-child {
        line-height: normal;
        padding-top: 5px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {
      li.selectBorrowerBlk:first-child,
      li.selectBorrowerBlk:last-child {
        display: block;
        width: 100%;
        margin: 0px;
      }

      li.selectBorrowerBlk:last-child {
        text-align: center;
        padding-top: 10px;
        font-size: $fontsize-12;
      }
    }
  }

  .progress_container {
    padding: 0.15em 0px;
  }

  .progress_bg {
    background: $color-blue-navi-form;
  }

  .progress_font {
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-10;
    text-align: center;
    line-height: 10px;
  }

  .progress_active {
    color: $color-offwhite;
    background-color: $color-blackbrown-light;
  }

  /*Form Saved Completed
    -------------------------------*/
  .form1003_savedCompleted_blk {
    display: block;
    margin-top: -15px;
    // border-radius: 5px 5px 0px 0px;
    border: 1px solid $color-offwhite;
    // border-top: 4px solid $color-blue-navi-form;

    .savedCompleted_head,
    .savedCompleted {
      table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin: 0px;
        padding: 0px;
        // border: 1px solid $color-white;
        border-bottom: 1px solid $color-offwhite;
        background: $color-blue-form-bg-dark;
        background-image: $bgTexure;

        td {
          font-family: $font-arial;
          font-weight: bold;
          font-size: $fontsize-14;
          color: $color-black;
          height: 35px;
          // line-height: 35px;
          padding: 5px 5px 0px 5px;
          width: auto;
        }

        td:first-child {
          width: 120px;
          padding-left: 10px;
        }

        td:nth-child(2) {
          width: 120px;
        }

        td:nth-child(3) {
          width: 150px;
        }

        td:nth-child(4) {
          width: 110px;
        }

        td:nth-child(5) {
          width: 175px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        td:nth-child(6) {
          width: auto;
        }

        td:nth-child(7) {
          width: 85px;
        }

        td:last-child {
          width: 40px;
          padding-right: 10px;
        }

        @media ($smaller-than: $breakpoint-799) {
          td:nth-child(4),
          td:nth-child(5) {
            display: none;
          }

          td:nth-child(3) {
            width: auto;
          }
        }
      }
    }

    .savedCompleted {
      table {
        background: $color-white;
        background-image: none;

        td {
          vertical-align: top;
          font-weight: normal;
          color: $color-blackbrown;
          height: 35px;
          border-bottom: 1px solid $color-offwhite;
        }

        /*15px less than head table*/

        td a {
          color: $color-blue;
          text-decoration: none;
        }

        td a:hover {
          color: $color-blackbrown-light;
        }

        tr:last-child td {
          border-bottom: 0px;
        }
      }
    }

    .form1003__downloadForm,
    .form1003__resumeForm {
      float: right;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      background: $download_whiteIcon_SVG $color-blue-navi-form;
      background-size: 9px;
      background-position: center center;
      margin-top: 7px;
    }

    .form1003__resumeForm {
      background: $resume_whiteIcon_SVG $color-blue-navi-form;
      background-size: 10px;
      background-position: center center;
    }

    .form1003__downloadForm:hover,
    .form1003__resumeForm:hover {
      background-color: $color-black;
      cursor: pointer;
    }

    @-moz-document url-prefix() {
      .savedCompleted {
        height: 109px;
      }
    }
  }

  .form1003_declarations {
    display: block;
    border: 1px solid $color-offwhite-dark;
    border-top: 4px solid $color-blue-navi-form;
    border-bottom: none;
    margin-bottom: 15px;
    border-radius: 5px 5px 0 0;

    select:required:invalid {
      color: $color-disable;
    }

    option {
      color: $color-lightblack;
    }

    select::-ms-expand {
      display: none;
    }

    select {
      width: 100%;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-blackbrown;
      border: 1px solid $color-blue-lighter;
      border-radius: 3px;
      outline: 0;
      padding: 0px 5px 0px 5px;
      height: 30px;
      background: $selectarrow_blackIcon_SVG;
      background-position: right 3% center;
      background-color: $color-white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    select:hover {
      cursor: pointer;
      background: $selectarrow_blueIcon_SVG;
      background-position: right 3% center;
      background-color: $color-white;
    }

    ul.form1003_declarations_bor_head {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-16;
      background-image: $bgTexure;

      li.form1003_declarations_bor_head {
        display: inline-block;
        vertical-align: top;
        padding: 8px 10px;
      }

      li.form1003_declarations_bor_head:nth-child(odd) {
        width: calc(100% - 165px);
        width: -webkit-calc(100% - 165px);
      }

      li.form1003_declarations_bor_head:nth-child(even) {
        width: 160px;
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_bor_head:nth-child(odd) {
          display: none;
          width: 100%;
        }

        li.form1003_declarations_bor_head:nth-child(even) {
          display: none;
        }
      }
    }

    ul.form1003_declarations_bor {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-size: $fontsize-15;

      li.form1003_declarations_bor {
        display: inline-block;
        vertical-align: top;
        padding: 10px 10px;
      }

      li.form1003_declarations_bor:nth-child(odd) {
        width: calc(100% - 165px);
        width: -webkit-calc(100% - 165px);
      }

      li.form1003_declarations_bor:nth-child(even) {
        width: 160px;
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_bor:nth-child(odd),
        li.form1003_declarations_bor:nth-child(even) {
          display: block;
          width: 100%;
        }

        li.form1003_declarations_bor:nth-child(odd) {
          padding-bottom: 0px;
        }

        li.form1003_declarations_bor:nth-child(even) {
          padding-bottom: 15px;
        }
      }
    }

    ul.form1003_declarations_borCobor_head {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-16;
      background-image: $bgTexure;

      li.form1003_declarations_borCobor_head {
        display: inline-block;
        vertical-align: top;
        padding: 8px 10px;
      }

      li.form1003_declarations_borCobor_head:first-child {
        width: calc(100% - 330px);
        width: -webkit-calc(100% - 330px);
      }

      li.form1003_declarations_borCobor_head:nth-child(2),
      li.form1003_declarations_borCobor_head:last-child {
        width: 160px;
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_borCobor_head:first-child {
          display: none;
          width: 100%;
        }

        li.form1003_declarations_borCobor_head:nth-child(2),
        li.form1003_declarations_borCobor_head:last-child {
          display: none;
        }
      }
    }

    ul.form1003_declarations_borCobor {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-size: $fontsize-15;

      .borCoborHeadLabel {
        display: none;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-15;
        color: $color-blackbrown;
        margin-bottom: 5px;
      }

      @media ($smaller-than: $breakpoint-799) {
        .borCoborHeadLabel {
          display: block;
        }
      }

      li.form1003_declarations_borCobor {
        display: inline-block;
        vertical-align: top;
        padding: 10px 10px;
      }

      li.form1003_declarations_borCobor:first-child {
        width: calc(100% - 330px);
        width: -webkit-calc(100% - 330px);
      }

      li.form1003_declarations_borCobor:nth-child(2),
      li.form1003_declarations_borCobor:last-child {
        width: 160px;
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_borCobor:first-child,
        li.form1003_declarations_borCobor:nth-child(2),
        li.form1003_declarations_borCobor:last-child {
          display: block;
          width: 100%;
        }

        li.form1003_declarations_borCobor:last-child {
          margin-bottom: 10px;
        }
      }
    }

    ul.form1003_declarations_bor_30by70_head {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-16;

      li.form1003_declarations_bor_30by70_head {
        display: inline-block;
        vertical-align: top;
        padding: 10px 10px;
        width: calc(100% / 2);
        width: -webkit-calc(100% / 2);
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_bor_30by70_head {
          display: block;
          width: 100%;
          padding-bottom: 0px;
        }
      }
    }

    ul.form1003_declarations_bor_30by70 {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-size: $fontsize-15;

      li.form1003_declarations_bor_30by70 {
        display: inline-block;
        vertical-align: top;
        padding: 10px 10px;
        width: calc(100% / 2);
        width: -webkit-calc(100% / 2);
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_bor_30by70 {
          display: block;
          width: 100%;
          padding-bottom: 0px;
        }
      }
    }

    ul.form1003_declarations_borCobor_30by70_head {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-16;
      background-image: $bgTexure;

      li.form1003_declarations_borCobor_30by70_head {
        display: inline-block;
        vertical-align: top;
        padding: 8px 10px;
      }

      li.form1003_declarations_borCobor_30by70_head:first-child {
        width: 350px;
      }

      li.form1003_declarations_borCobor_30by70_head:nth-child(2),
      li.form1003_declarations_borCobor_30by70_head:last-child {
        width: calc(100% / 2 - 175px);
        width: -webkit-calc(100% / 2 - 175px);
      }

      @media ($smaller-than: $breakpoint-799) {
        li.form1003_declarations_borCobor_30by70_head:first-child {
          display: none;
          width: 100%;
        }

        li.form1003_declarations_borCobor_30by70_head:nth-child(2),
        li.form1003_declarations_borCobor_30by70_head:last-child {
          display: none;
        }
      }
    }

    ul.form1003_declarations_borCobor_30by70 {
      display: block;
      border-bottom: 1px solid $color-offwhite-dark;
      font-family: $font-arial;
      font-size: $fontsize-15;

      li.form1003_declarations_borCobor_30by70 {
        display: inline-block;
        vertical-align: top;
        padding: 10px 10px;
      }

      li.form1003_declarations_borCobor_30by70:first-child {
        width: 350px;
      }

      li.form1003_declarations_borCobor_30by70:nth-child(2),
      li.form1003_declarations_borCobor_30by70:last-child {
        width: calc(100% / 2 - 175px);
        width: -webkit-calc(100% / 2 - 175px);
      }
    }
  }

  .form1003_declare_Qblk {
    display: block;
    margin: 0px auto 40px auto;
    background-image: $bgTexure;
    border: 1px solid $color-offwhite;

    .qNo {
      display: block;
      width: 15%;
      text-align: center;
      margin: 15px auto -10px auto;
      padding: 5px 0;
      // background: $color-blue-button-bg;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-20;
      color: $color-white;
      border-radius: 5px;
    }

    .form1003_declare_bor_cobor {
      display: block;
      width: 96%;
      margin: 30px auto 10px auto;
    }

    .form1003_declare_Q {
      display: block;
      width: 80%;
      margin: 30px auto 10px auto;
      text-align: center;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-26;
      color: $color-blackbrown;
    }

    @media ($smaller-than: $breakpoint-999) {
      .qNo {
        width: 50%;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      .qNo {
        width: 60%;
        font-size: $fontsize-18;
      }

      .form1003_declare_Q {
        width: 100%;
        font-size: $fontsize-20;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      .qNo {
        width: 60%;
        font-size: $fontsize-16;
      }
    }

    ul.form1003_declare_ansBlk {
      display: block;
      width: 50%;
      margin: 0px auto 40px auto;
      padding: 0px;
      text-align: center;

      li.form1003_declare_borrower,
      li.form1003_declare_borrower_left {
        display: block;
        padding: 0px;
        width: 300px;
        margin: 0px auto;
      }

      li.form1003_declare_borrower_left {
        text-align: left;
      }

      li.form1003_declare_co_borrower,
      li.form1003_declare_co_borrower_left {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 15px);
        width: -webkit-calc(100%/2 - 15px);
        padding: 0px;
        margin-right: 25px;
      }

      li.form1003_declare_co_borrower_left {
        text-align: left;
      }

      li.form1003_declare_co_borrower:last-child,
      li.form1003_declare_co_borrower_left:last-child {
        margin-right: 0px;
        border: 0px;
      }

      .form1003_declare_ans_head {
        display: block;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-22;
        color: $color-lightblack;
        margin: 5px 0 3px 0;
        padding: 0px;
        text-align: center;
      }

      ul.declare_form_A {
        display: block;
        padding: 0;

        li.declare_form_A {
          display: inline-block;
          margin-right: 10px;
        }

        li.declare_form_A a {
          display: inline-block;
          font-family: $font-arial;
          font-weight: bold;
          font-size: $fontsize-22;
          color: $color-blackbrown;
          background: $color-white;
          text-decoration: none;
          padding: 0px 20px;
          height: 50px;
          line-height: 50px;
          border: 1px solid $color-blue-navi-form;
          border-radius: 3px;
          transition: background 0.5s, color 0.5s;
          -webkit-transition: background 0.5s, color 0.5s;
        }

        li.declare_form_A:last-child {
          margin-right: 0px;
        }

        li.declare_form_A a:hover {
          position: relative;
          background-image: $bgTexure;
          color: $color-black;
        }

        li.declare_form_A a.current {
          position: relative;
          color: $color-white;
          // background: $color-blue-bg-dark;
          background-image: $bgTexure;
          // border-color: $color-blue-bg-dark;
          cursor: default;
        }

        @media ($smaller-than: $breakpoint-699) {
          li.declare_form_A a {
            font-size: $fontsize-18;
            padding: 0px 15px;
            height: 35px;
            line-height: 35px;
          }
        }
      }

      @media ($smaller-than: $breakpoint-699) {
        .form1003_declare_ans_head {
          font-size: $fontsize-18;
        }
      }
    }

    @media ($smaller-than: $breakpoint-999) {
      ul.form1003_declare_ansBlk {
        width: 70%;
      }
    }

    @media ($smaller-than: $breakpoint-799) {
      ul.form1003_declare_ansBlk {
        width: 100%;
      }
    }

    .form1003_declare_Q_Ans_but_blk {
      display: block;
      margin: -10px 0 0 0;
      padding: 15px 0;
      text-align: center;
      border-top: 1px solid $color-offwhite;
      background-image: $bgTexure;

      .form1003_declare_Q_Ans_but {
        display: inline-block;
        vertical-align: middle;
        padding: 0px 25px;
        height: 45px;
        margin-right: 5px;
        border: 0px solid $color-blue;
        border-radius: 5px;
        font-family: $font-arial;
        font-size: $fontsize-20;
        color: $color-white;
        outline: none;
        background: $color-blue;
        text-align: center;
      }

      .form1003_declare_Q_Ans_but:hover {
        background: $color-blue-navi;
        cursor: pointer;
      }

      .form1003_declare_Q_Ans_but[disabled],
      .form1003_declare_Q_Ans_but[disabled]:hover,
      .form1003_declare_Q_Ans_but.disabled,
      .form1003_declare_Q_Ans_but.disabled:hover {
        background: $color-lightblack;
        color: $color-offblack;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-disable;
      }

      @media ($smaller-than: $breakpoint-699) {
        .form1003_declare_Q_Ans_but {
          display: block;
          height: 35px;
          width: 80%;
          margin: 0px auto 10px auto;
          padding: 0px 15px;
          font-size: $fontsize-18;
        }
      }
    }

    .form1003_declare_successError_blk {
      display: block;
      margin: 15px auto 0 auto;
      text-align: center;
      width: 80%;
    }
  }

  .childBlock {
    display: block;
  }

  .form1003Icol {
    display: block;
    margin: 0px 0px 25px 0px;
    padding: 0px;
  }

  ul.form1003IIcol {
    display: block;
    margin: 0px 0 0 0;
    padding: 0px;

    li.form1003IIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 10px);
      width: -webkit-calc(100%/2 - 10px);
      margin-right: 20px;
    }

    li.form1003IIcol:nth-child(even) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.form1003IIcol {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  ul.form1003IIIcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.form1003IIIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 16px);
      width: -webkit-calc(100%/3 - 16px);
      margin-right: 20px;
    }

    li.form1003IIIcol:nth-child(3) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.form1003IIIcol {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  .childQno {
    position: relative;
    left: 50%;
    transform: translate(-50%, 4px);
    text-align: center;
    width: 56px;
    height: 28px;
    line-height: 28px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border: 4px solid $color-blue-navi-form;
    border-bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: $color-blue-form-bg-dark;
    background-image: $bgTexure;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-16;
    color: $color-blackbrown;
    /*background:
        radial-gradient(circle at top right,transparent 50%,#29a7e8 51%)100% 21px/12px 10px no-repeat,
        radial-gradient(circle at top left,transparent 50%,#29a7e8 51%)0 21px/12px 10px no-repeat,
        radial-gradient(circle at center,#29a7e8 55%, transparent 56%);
    	width: 60px;
        height: 60px;
        margin: 0 1rem;*/
  }

  @media ($smaller-than: $breakpoint-699) {
    .childQno {
      display: none;
    }
  }

  .form1003IIcol_declare {
    display: block;
    margin: 0 0 25px 0;
    padding: 0px;
    border: 1px solid $color-offwhite;
    background-image: $bgTexure;
    border-radius: 5px 5px 7px 7px;
    border-top: 4px solid $color-blue-navi-form;

    .form1003ContentHead_declare {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-17;
      color: $color-blackbrown;
      margin: 0px;
      padding: 15px 20px 10px 20px;
      background: $color-blue-form-bg-dark;
      background-image: $bgTexure;
    }

    ul.form1003IIcol_declare_borrower_coborrower_blk {
      display: block;
      padding: 0px 20px 10px 20px;

      li.form1003IIcol_declare_borrower {
        display: block;
        padding: 10px 20px;
        margin: 0px;
      }

      li.form1003IIcol_declare_coborrower {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 7px);
        width: -webkit-calc(100%/2 - 7px);
        padding: 10px 0px;
        margin-right: 10px;
      }

      li.form1003IIcol_declare_coborrower:nth-child(even) {
        padding-left: 20px;
      }

      li.form1003IIcol_declare_coborrower:nth-child(odd) {
        padding-right: 20px;
      }

      li.form1003IIcol_declare_coborrower:last-child {
        margin-right: 0px;
        border: 0px;
      }
    }

    .form1003_update_but_blk {
      display: block;
      background-image: $bgTexure;
      border-top: 1px solid $color-offwhite;
      padding: 10px 0 10px 20px;

      .form1003_update_but {
        display: block;
        padding: 5px 12px;
        border: none;
        border-radius: 3px;
        font-family: $font-arial;
        font-size: $fontsize-12;
        font-weight: bold;
        color: $color-white;
        outline: none;
        background: $color-blue;
        text-align: center;
        margin: 0 auto;
      }

      .form1003_update_but:hover {
        // background: $color-blue-button-bg;
        cursor: pointer;
      }

      .form1003_update_but[disabled],
      .form1003_update_but[disabled]:hover,
      .form1003_update_but.disabled,
      .form1003_update_but.disabled:hover {
        background: $color-lightblack;
        color: $color-offblack;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-disable;
      }
    }
  }

  .form1003BlkHead,
  .form1003BlkInsideHead,
  .form1003BlkInsideHead_topMinus40,
  .form1003BlkHead_Error {
    display: block;
    border: 1px solid $color-offwhite;
    border-top: 4px solid $color-blue-navi-form;
    border-bottom: none;
    padding: 10px 20px;
    background: $color-blue-form-bg-dark;
    background-image: $bgTexure;
    border-radius: 5px 5px 0 0;

    .formHeadContent,
    .formHeadContent_85pc,
    .formHeadContent_70pc {
      display: inline-block;
      vertical-align: top;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-17;
      color: $color-black;
      height: 25px;
      line-height: 25px;
    }

    .formHeadContent_70pc {
      font-size: $fontsize-15;
    }

    .formTipHolder {
      float: right;
      margin-top: 12px;
    }

    .formMandatoryHead {
      display: inline;
      margin-top: 12px;
      margin-left: 3px;
      height: 3px;
      font-family: $font-arial;
      font-size: $fontsize-24;
      color: $color-alert;
    }

    .form1003Blk_right {
      float: right;
    }

    .headRightLabel,
    .headRightLabel_chkbox {
      margin-top: 10px;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-blackbrown;
    }

    .headRightLabel_chkbox {
      margin-top: 5px;
    }

    .headRight_button {
      display: inline-block;
      vertical-align: top;
      background: $color-blue-darklight;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-12;
      color: $color-white;
      border-radius: 3px;
      border: none;
      padding: 0 10px 0 5px;
      margin: 0px;
      height: 25px;
      outline: none;
    }

    .headRight_button:hover {
      cursor: pointer;
      background: $color-blue;
    }

    @media ($smaller-than: $breakpoint-799) {
      .formHeadContent,
      .formHeadContent_85pc {
        height: auto;
        line-height: normal;
      }

      .formHeadContent_85pc {
        width: 85%;
      }

      .formHeadContent_70pc {
        width: 70%;
      }
    }
  }

  .form1003BlkInsideHead,
  .form1003BlkInsideHead_topMinus40 {
    border-top: none;
    margin: 0 -20px 20px -20px;
    border-radius: 0px;
  }

  .form1003BlkInsideHead_topMinus40 {
    margin-top: -50px;
  }

  .form1003BlkHead_Error {
    border-top-color: $color-red;
  }

  @media ($smaller-than: $breakpoint-799) {
    .form1003BlkInsideHead_topMinus40 {
      padding-bottom: 30px;
    }
  }

  .form1003BlkInsideDivider {
    display: block;
    margin: 0 -20px 20px -20px;
    height: 10px;
    background: $color-blue-form-bg-dark;
    background-image: $bgTexure;
  }

  .form1003ContentBlk {
    display: block;
    background-image: $bgTexure;
    border: 1px solid $color-offwhite;
    padding: 20px 20px 10px 20px;
    border-radius: 0 0 7px 7px;
    margin-bottom: 25px;

    .form1003_update_but_blk_ms {
      display: block;
      background-image: $bgTexure;
      border-top: 1px solid $color-offwhite;
      padding: 10px 0 10px 20px;
      margin: 0 -20px -10px -20px;

      .form1003_update_but_ms {
        display: block;
        padding: 5px 12px;
        border: none;
        border-radius: 3px;
        font-family: $font-arial;
        font-size: $fontsize-12;
        font-weight: bold;
        color: $color-white;
        outline: none;
        background: $color-blue;
        text-align: center;
        margin: 0 auto;
      }

      .form1003_update_but_ms:hover {
        // background: $color-blue-button-bg;
        cursor: pointer;
      }

      .form1003_update_but_ms[disabled],
      .form1003_update_but_ms[disabled]:hover,
      .form1003_update_but_ms.disabled,
      .form1003_update_but_ms.disabled:hover {
        background: $color-lightblack;
        color: $color-offblack;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-disable;
      }
    }
  }

  ul.formContentIIcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.formContentIIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 8px);
      width: -webkit-calc(100%/2 - 8px);
      margin-right: 15px;
      margin-bottom: 20px;
    }

    li.formContentIIcol:nth-child(even) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.formContentIIcol {
        display: block;
        width: 100%;
        margin-right: 0px;
      }
    }
  }

  ul.formContentIIcol_noBot {
    display: block;
    margin: 0px;
    padding: 0px;

    li.formContentIIcol_noBot {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 7.5px);
      width: -webkit-calc(100%/2 - 7.5px);
      margin-right: 15px;
    }

    li.formContentIIcol_noBot:nth-child(even) {
      margin-right: 0px;
    }
  }

  ul.formContentIIcol_20by80 {
    display: block;
    margin: 0px;
    padding: 0px;

    li.formContentIIcol_20by80 {
      display: inline-block;
      vertical-align: top;
      width: calc(60% / 2 - 10px);
      width: -webkit-calc(60%/2 - 10px);
      margin-right: 15px;
      margin-bottom: 20px;
    }

    li.formContentIIcol_20by80:nth-child(even) {
      width: calc(140% / 2 - 10px);
      width: -webkit-calc(140%/2 - 10px);
      margin-right: 0px;
    }
  }

  ul.formContentIIIcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.formContentIIIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 13px);
      width: -webkit-calc(100%/3 - 13px);
      margin-right: 15px;
      margin-bottom: 20px;
    }

    li.formContentIIIcol:nth-child(3),
    li.formContentIIIcol:nth-child(6),
    li.formContentIIIcol:nth-child(9),
    li.formContentIIIcol:nth-child(12) {
      margin-right: 0px;
    }
  }

  ul.formContentIVcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.formContentIVcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 4 - 12px);
      width: -webkit-calc(100% / 4 - 12px);
      margin: 0px 15px 25px 0px;
    }

    li.formContentIVcol:nth-child(4n + 0) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.formContentIVcol {
        width: calc(100% / 3 - 10px);
        width: -webkit-calc(100% / 3 - 10px);
      }

      li.formContentIVcol:nth-child(4n + 0) {
        margin-right: 15px;
      }

      li.formContentIVcol:nth-child(3n + 0) {
        margin-right: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-899) {
      li.formContentIVcol {
        width: calc(100% / 2 - 8px);
        width: -webkit-calc(100% / 2 - 8px);
      }

      li.formContentIVcol:nth-child(3n + 0) {
        margin-right: 15px;
      }

      li.formContentIVcol:nth-child(even) {
        margin-right: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-799) {
      li.formContentIVcol {
        display: block;
        width: 100%;
        margin-right: 0px;
      }
    }
  }

  .form1003ContentHead,
  .form1003ContentHead_Error {
    display: inline-block;
    vertical-align: top;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-14;
    color: $color-blackbrown;
    margin-bottom: 3px;
  }

  .form1003ContentHead_Error {
    color: $color-alert;
  }

  .form1003ContentHead_right {
    float: right;
  }

  .form1003NoteText {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-14;
    color: $color-disable;
  }

  .contentHeadRight_button {
    display: inline-block;
    vertical-align: top;
    background: $color-blue;
    font-family: $font-arial;
    font-size: $fontsize-10;
    color: $color-white;
    border-radius: 2px;
    border: none;
    padding: 0 7px;
    height: 17px;
    outline: none;
    margin-left: 5px;
  }

  .contentHeadRight_button:hover {
    cursor: pointer;
    background: $color-blue;
  }

  .formMandatory {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    height: 3px;
    font-family: $font-arial;
    font-size: $fontsize-24;
    color: $color-alert;
  }

  .formTip {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    width: 15px;
    height: 15px;
    background: $infoFormIcon_SVG;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .blkInsidePartition {
    display: block;
    margin: 0 -20px 20px -20px;
    background: $color-offwhite;
    height: 3px;
  }

  .formCheckboxLabel {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-blackbrown;
  }

  .formPartition {
    display: block;
    margin-bottom: 15px;
    border-top: 1px solid $color-blue-darklight;

    .closePartition {
      width: 65px;
      margin: -1px auto 0 auto;
      border-radius: 0 0 4px 4px;
      background: $color-blue-darklight;
      color: $color-black;
      text-align: center;
    }

    .closePartition a {
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-11;
      color: $color-white;
    }

    .closePartition a:hover {
      text-decoration: none;
      color: $color-whiteblue;
    }
  }

  ::-webkit-input-placeholder {
    color: $color-lightblack;
  }

  :-moz-placeholder {
    color: $color-lightblack;
  }

  ::-moz-placeholder {
    color: $color-lightblack;
  }

  :-ms-input-placeholder {
    color: $color-lightblack;
  }

  select:required:invalid {
    color: $color-disable;
  }

  option {
    color: $color-lightblack;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    width: 100%;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-black;
    border: 1px solid $color-blue-lighter;
    border-radius: 3px;
    outline: 0;
    padding: 0px 25px 0px 46px;
    height: 40px;
    background: $selectarrow_blackIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blueIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
  }

  input[type="text"] {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-black;
    width: 100%;
    padding: 0px 10px 0px 46px;
    height: 40px;
    border: 1px solid $color-blue-lighter;
    border-radius: 3px;
    background: $color-white;
    outline: 0;
  }

  .textPad10 > input[type="text"] {
    padding-left: 10px;
  }

  .textDeclare > input[type="text"] {
    padding-left: 55px;
    height: 52px;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-22;
    border-color: $color-blue-navi-form;
  }

  .textError > input[type="text"],
  .selectError > select {
    border-color: $color-red;
  }

  .selectDeclare > select {
    padding-left: 55px;
    height: 52px;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-22;
    border-color: $color-blue-navi-form;
  }

  .selectPadLeft10 > select {
    padding-left: 10px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
    cursor: pointer;
  }

  input[type="checkbox"]:not([disabled]) + label,
  input[type="radio"]:not([disabled]) + label {
    cursor: pointer;
  }

  input[type="radio"] + label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -5px;
    background: $color-white;
    border-radius: 50%;
    border: 1px solid $color-blue-lighter;
  }

  input[type="radio"]:checked + label:before {
    content: "\2022";
    color: $color-blue;
    font-family: $font-arial;
    font-size: $fontsize-28;
    text-align: center;
    line-height: 23px;
    border: 1px solid $color-blue-lighter;
  }

  input[type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: top;
    margin: 0 7px 3px 0;
    border-radius: 3px;
    border: 1px solid $color-blue-lighter;
    background: $color-white;
  }

  input[type="checkbox"]:checked + label:before {
    content: "\2714";
    color: $color-blue;
    font-family: $font-arial;
    font-size: $fontsize-14;
    text-align: center;
  }

  ul.form_A {
    display: block;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 7px;
    }

    li a {
      display: inline-block;
      font-family: $font-arial;
      $font-size: $fontsize-14;
      color: $color-black;
      background: $color-white;
      text-decoration: none;
      padding: 0px 15px;
      height: 40px;
      line-height: 40px;
      border: 1px solid $color-blue-lighter;
      border-radius: 3px;
    }

    li:last-child {
      margin-right: 0px;
    }

    a:hover {
      position: relative;
      background-image: $bgTexure;
      color: $color-black;
    }

    a.current {
      position: relative;
      color: $color-white;
      background: $color-blue-bg-dark;
      background-image: $bgTexure;
      border-color: $color-blue-bg-dark;
      cursor: default;
    }

    @media ($smaller-than: $breakpoint-599) {
      li a {
        margin-bottom: 15px;
      }
    }
  }

  .usericon,
  .mailicon,
  .phicon,
  .noteicon,
  .locationicon,
  .countryicon,
  .form_dollaricon,
  .form_dollaricon_declare,
  .form_percenticon,
  .lockicon,
  .matitalicon,
  .form_dateicon,
  .form_homeicon,
  .form_homeicon_declare,
  .hastagicon,
  .gifticon,
  .officeicon,
  .typeicon,
  .timeicon {
    position: absolute;
    float: left;
    width: 40px;
    height: 38px;
    border-right: 1px solid $color-blue-lighter;
    margin: 1px 0 0 1px;
    border-radius: 3px 0 0 3px;
  }

  .usericon {
    background: $userFormIcon_SVG $color-blue-bg-light;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .mailicon {
    background: $mailFormIcon_SVG $color-blue-bg-light;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .phicon {
    background: $phFormIcon_SVG $color-blue-bg-light;
    background-size: 10px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .noteicon {
    background: $noteFormIcon_SVG $color-blue-bg-light;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .locationicon {
    background: $locationFormIcon_SVG $color-blue-bg-light;
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .countryicon {
    background: $countryFormIcon_SVG $color-blue-bg-light;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_dollaricon {
    background: $dollarFormIcon_SVG $color-blue-bg-light;
    background-size: 10px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_dollaricon_declare {
    height: 50px;
    width: 45px;
    border-right-color: $color-blue-navi-form;
    background: $dollarFormIcon_SVG $color-blue-bg-light;
    background-size: 14px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_percenticon {
    background: $percentFormIcon_SVG $color-blue-bg-light;
    background-size: 14px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .lockicon {
    background: $lockFormIcon_SVG $color-blue-bg-light;
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .matitalicon {
    background: $maritalFormIcon_SVG $color-blue-bg-light;
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_dateicon {
    background: $dateFormIcon_SVG $color-blue-bg-light;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_homeicon {
    background: $homeFormIcon_SVG $color-blue-bg-light;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_homeicon_declare {
    height: 50px;
    width: 45px;
    border-right-color: $color-blue-navi-form;
    background: $homeFormIcon_SVG $color-blue-bg-light;
    background-size: 24px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .hastagicon {
    background: $numberFormIcon_SVG $color-blue-bg-light;
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .gifticon {
    background: $giftFormIcon_SVG $color-blue-bg-light;
    background-size: 14px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .officeicon {
    background: $officeFormIcon_SVG $color-blue-bg-light;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .typeicon {
    background: $typeFormIcon_SVG $color-blue-bg-light;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .timeicon {
    background: $timeFormIcon_SVG $color-blue-bg-light;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  ul.form_btn_successError_blk {
    display: block;
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    border-top: 5px solid $color-offwhite;

    li.form_btn_steps_label,
    li.form_btn_steps_srt_label {
      display: inline-block;
      vertical-align: top;
      width: 350px;
      margin-right: 5px;

      .form_btn_steps {
        display: inline-block;
        vertical-align: middle;
        padding: 0px 29px;
        height: 45px;
        margin-right: 8px;
        border: 0px solid $color-blue;
        border-radius: 5px;
        font-family: $font-arial;
        font-size: $fontsize-17;
        color: $color-white;
        outline: none;
        background: $color-blue;
        text-align: center;
      }

      .form_btn_steps:hover {
        background: $color-blue-navi;
        cursor: pointer;
      }

      .form_btn_steps[disabled],
      .form_btn_steps[disabled]:hover,
      .form_btn_steps.disabled,
      .form_btn_steps.disabled:hover {
        background: $color-lightblack;
        color: $color-offblack;
        cursor: default;
        text-shadow: none;
        position: static;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px dotted $color-disable;
      }
    }

    li.form_btn_steps_srt_label {
      width: 110px;
    }

    li.form_successError_steps {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 355px);
      width: -webkit-calc(100% - 355px);
    }

    li.form_successError_srt_steps {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 120px);
      width: -webkit-calc(100% - 120px);
    }

    @media ($smaller-than: $breakpoint-899) {
      li.form_btn_steps_label {
        display: block;
        width: 100%;
        margin: 0 0 10px 0;
      }

      li.form_successError_steps {
        display: block;
        width: 100%;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      li.form_btn_steps_srt_label {
        display: block;
        width: 100%;
        margin: 0 0 10px 0;
      }

      li.form_successError_srt_steps {
        display: block;
        width: 100%;
      }
    }
  }

  .formFinishHead {
    display: block;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-34;
    color: $color-blue-logo;
    text-align: center;
    margin: 20px 0 50px 0;
  }

  .formFinishText,
  .formFinishText_b {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-20;
    color: $color-blackbrown;
    text-align: center;
    margin-bottom: 10px;
  }

  .formFinishText_b {
    font-weight: bold;
    margin-bottom: 20px;
  }
}

@media ($smaller-than: $breakpoint-799) {
  .form1003_pgBlk {
    padding: 15px;
  }
}
