@import "support";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Use the variables */
body {
  background: $color-white;
  font-family: $font-agaramond;
  font-size: $fontsize-16;
  width: 100%;
  height: 100%;
  color: $color-black;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body > #root {
  height: 100%;
  width: 100%;
}

body > #root > #container {
  height: auto;
  min-height: 100%;
}

#container {
  color: $color-black;
  height: 100%;
  background: $color-white;
  background-image: $bgTexure;

  #content {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #content_fullHeader {
    padding-top: 92px;
    padding-bottom: 100px;
  }

  @media ($smaller-than: $breakpoint-1200) {
    #content_fullHeader {
      padding-top: 42px;
    }
  }
}

/*Inner Page Hero Image*/
#heroImg_about,
#heroImg_statistics,
#heroImg_career,
#heroImg_blog,
#heroImg_review,
#heroImg_contact,
#heroImg_buy_first,
#heroImg_buy_next,
#heroImg_buy_newhome,
#heroImg_buy_residential,
#heroImg_buy_foreclosed,
#heroImg_lo_30,
#heroImg_lo_va,
#heroImg_lo_fha,
#heroImg_lo_reverse,
#heroImg_lo_usda,
#heroImg_lo_jumbo,
#heroImg_lo_renovation,
#heroImg_lo_nonqm,
#heroImg_lo_conventional,
#heroImg_refin_lower,
#heroImg_refin_reduce,
#heroImg_refin_payoff,
#heroImg_refin_prepare,
#heroImg_refin_steps {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0px;
}

#heroImg_about {
  background-image: url("../img/heroImg_about.jpg");
}

#heroImg_statistics {
  background-image: url("../img/heroImg_statistics.jpg");
}

#heroImg_career {
  background-image: url("../img/heroImg_career.jpg");
}

#heroImg_blog {
  background-image: url("../img/heroImg_blog.jpg");
}

#heroImg_review {
  background-image: url("../img/heroImg_review.jpg");
}

#heroImg_contact {
  background-image: url("../img/heroImg_contact.jpg");
}

#heroImg_buy_first {
  background-image: url("../img/heroImg_buy_first.jpg");
}

#heroImg_buy_next {
  background-image: url("../img/heroImg_buy_next.jpg");
}

#heroImg_buy_newhome {
  background-image: url("../img/heroImg_buy_newhome.jpg");
}

#heroImg_buy_residential {
  background-image: url("../img/heroImg_buy_residential.jpg");
}

#heroImg_buy_foreclosed {
  background-image: url("../img/heroImg_buy_foreclosed.jpg");
}

#heroImg_lo_30 {
  background-image: url("../img/heroImg_lo_30.jpg");
}

#heroImg_lo_va {
  background-image: url("../img/heroImg_lo_va.jpg");
}

#heroImg_lo_fha {
  background-image: url("../img/heroImg_lo_fha.jpg");
}

#heroImg_lo_reverse {
  background-image: url("../img/heroImg_lo_reverse.jpg");
}

#heroImg_lo_usda {
  background-image: url("../img/heroImg_lo_usda.jpg");
}

#heroImg_lo_jumbo {
  background-image: url("../img/heroImg_lo_jumbo.jpg");
}

#heroImg_lo_renovation {
  background-image: url("../img/heroImg_lo_renovation.jpg");
}

#heroImg_lo_nonqm {
  background-image: url("../img/heroImg_lo_nonqm.jpg");
}

#heroImg_lo_conventional {
  background-image: url("../img/heroImg_lo_conventional.jpg");
}

#heroImg_refin_lower {
  background-image: url("../img/heroImg_refin_lower.jpg");
}

#heroImg_refin_reduce {
  background-image: url("../img/heroImg_refin_reduce.jpg");
}

#heroImg_refin_payoff {
  background-image: url("../img/heroImg_refin_payoff.jpg");
}

#heroImg_refin_prepare {
  background-image: url("../img/heroImg_refin_prepare.jpg");
}

#heroImg_refin_steps {
  background-image: url("../img/heroImg_refin_steps.jpg");
}

@media ($smaller-than: $breakpoint-1200) {
  #heroImg_about,
  #heroImg_statistics,
  #heroImg_career,
  #heroImg_blog,
  #heroImg_review,
  #heroImg_contact,
  #heroImg_buy_first,
  #heroImg_buy_next,
  #heroImg_buy_newhome,
  #heroImg_buy_residential,
  #heroImg_buy_foreclosed,
  #heroImg_lo_30,
  #heroImg_lo_va,
  #heroImg_lo_fha,
  #heroImg_lo_reverse,
  #heroImg_lo_usda,
  #heroImg_lo_jumbo,
  #heroImg_lo_renovation,
  #heroImg_lo_nonqm,
  #heroImg_lo_conventional,
  #heroImg_refin_lower,
  #heroImg_refin_reduce,
  #heroImg_refin_payoff,
  #heroImg_refin_prepare,
  #heroImg_refin_steps {
    background-position-y: inherit;
  }
}

@media ($smaller-than: $breakpoint-899) {
  #heroImg_about,
  #heroImg_statistics,
  #heroImg_career,
  #heroImg_blog,
  #heroImg_review,
  #heroImg_contact,
  #heroImg_buy_first,
  #heroImg_buy_next,
  #heroImg_buy_newhome,
  #heroImg_buy_residential,
  #heroImg_buy_foreclosed,
  #heroImg_lo_30,
  #heroImg_lo_va,
  #heroImg_lo_fha,
  #heroImg_lo_reverse,
  #heroImg_lo_usda,
  #heroImg_lo_jumbo,
  #heroImg_lo_renovation,
  #heroImg_lo_nonqm,
  #heroImg_lo_conventional,
  #heroImg_refin_lower,
  #heroImg_refin_reduce,
  #heroImg_refin_payoff,
  #heroImg_refin_prepare,
  #heroImg_refin_steps {
    margin-bottom: 25px;
  }
}

//BreadCrumb Text Style
ul.bc_bg {
  display: block;
  background: $bgBlackTransparent_60;

  li.bc_col_100pc {
    display: block;
  }

  li.bc_col {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 400px);
    width: -webkit-calc(100% - 400px);
  }

  li.bc_col:last-child {
    width: 400px;

    .bcRightBlk {
      display: block;
      margin-top: 110px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font-family: $font-times;
      font-weight: bold;
      font-size: $fontsize-28;
      color: $color-red;
      background: $bgTransparent_95;
    }
  }

  @media ($smaller-than: $breakpoint-899) {
    li.bc_col {
      width: 100%;
      margin: 0px;
      padding: 0px;
    }

    li.bc_col:last-child {
      display: none;
    }
  }

  .bcBlkDesc {
    display: block;
    margin: 80px 0px 5px 0px;

    .blkDescTxt {
      display: inline-block;
      vertical-align: top;
      font-family: $font-agaramondB;
      font-size: $fontsize-14;
      color: $color-red;
      background: $color-white;
      text-transform: uppercase;
      padding: 0px 0px 0px 12px;
      height: 27px;
      line-height: 30px;
    }

    .rightCurve {
      display: inline-block;
      vertical-align: top;
      width: 0;
      height: 27px;
      border-left: 7px solid $color-white;
      border-right: 20px solid transparent;
      border-bottom: 27px solid $color-white;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    @media ($smaller-than: $breakpoint-899) {
      .blkDescTxt {
        font-size: $fontsize-12;
      }
    }
  }

  @media ($smaller-than: $breakpoint-899) {
    .bcBlkDesc {
      margin-top: 50px;
    }
  }

  .bcHeadTxt,
  .bcHeadTxt_topTxt_noRight,
  .bcHeadTxt_generic,
  .bcHeadTxt_generic_inline,
  .bcHeadTxt_generic_inline_support {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-42;
    color: $color-white;
    padding-left: 10px;
    text-transform: capitalize;
    text-shadow: 0px 1px $color-black;
  }

  .bcHeadTxt_generic {
    margin: 112px 0px 17px 0px;
  }

  .bcHeadTxt_generic_inline {
    display: inline-block;
    vertical-align: top;
    margin: 112px 3px 17px 0px;
  }

  .bcHeadTxt_generic_inline_support {
    display: inline-block;
    vertical-align: top;
    font-family: $font-agaramond;
    margin: 120px 0px 17px 0px;
    font-size: $fontsize-30;
  }

  .bcHeadTxt_topTxt_noRight {
    margin: 0px 0px 18px 0px;
  }

  @media ($smaller-than: $breakpoint-899) {
    .bcHeadTxt,
    .bcHeadTxt_topTxt_noRight,
    .bcHeadTxt_generic,
    .bcHeadTxt_generic_inline {
      font-size: $fontsize-30;
      margin-bottom: 15px;
    }

    .bcHeadTxt_generic,
    .bcHeadTxt_generic_inline {
      margin-top: 83px;
    }

    .bcHeadTxt_generic_inline_support {
      display: none;
    }
  }
}

//Common Inner Page Style
.Icol {
  display: block;
  width: 100%;
  margin-top: 30px;
}

ul.IIcol {
  display: block;
  margin: 30px 0px 0px 0px;
  padding: 0px;

  li.IIcol {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 2 - 13px);
    width: -webkit-calc(100%/2 - 13px);
    margin-right: 25px;
  }

  li.IIcol:nth-child(even) {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.IIcol {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}

ul.IIIcol {
  display: block;
  margin: 30px 0px 0px 0px;
  padding: 0px;

  li.IIIcol {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 3 - 17px);
    width: -webkit-calc(100% / 3 - 17px);
    margin-right: 25px;
  }

  li.IIIcol:nth-child(3n + 0) {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.IIIcol {
      display: block;
      width: 100%;
      margin: 0px 0px 20px 0px;
    }
  }
}

ul.IIcol_100by400 {
  display: block;

  li.IIcol_100by400 {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 430px);
    width: -webkit-calc(100% - 430px);
    margin: 30px 30px 0px 0px;
  }

  li.IIcol_100by400:last-child {
    width: 400px;
    margin: 0px;
  }

  @media ($smaller-than: $breakpoint-899) {
    li.IIcol_100by400,
    li.IIcol_100by400:last-child {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}

//Form Styles
::-webkit-input-placeholder {
  color: $color-lightblack;
}

:-moz-placeholder {
  color: $color-lightblack;
}

::-moz-placeholder {
  color: $color-lightblack;
}

:-ms-input-placeholder {
  color: $color-lightblack;
}

select:required:invalid {
  color: $color-disable;
}

option {
  color: $color-lightblack;
}

select::-ms-expand {
  display: none;
}

.comingSoonText {
  margin: 0px auto;
  width: 250px;
  padding: 10px 0px;
  background: $color-red;
  text-align: center;
  font-family: $font-agaramondB;
  font-size: $fontsize-24;
  color: $color-white;
}

.pgTxtNormal,
.pgTxtBold,
.pgTxtBold_L,
.pgTxtBold_XL,
.pgTxtBoldNoTopSpace {
  display: block;
  font-family: $font-agaramond;
  font-size: $fontsize-19;
  text-align: justify;
  margin: 0px 10px 20px 15px;
  color: $color-black;
  line-height: 30px;
}

.pgTxtBold {
  font-family: $font-agaramondB;
  font-size: $fontsize-20;
  margin: 40px 10px 20px 15px;
}

.pgTxtBold_L {
  font-family: $font-agaramondB;
  font-size: $fontsize-24;
  margin-bottom: 15px;
}

.pgTxtBold_XL {
  font-family: $font-agaramondB;
  font-size: $fontsize-30;
  margin-bottom: 15px;
}

.pgTxtBoldNoTopSpace {
  font-family: $font-agaramondB;
  font-size: $fontsize-20;
}

.pgTxtNormal a {
  text-decoration: none;
  color: $color-blue;
}

.pgTxtNormal a:hover {
  color: $color-black;
}

@media ($smaller-than: $breakpoint-599) {
  .pgTxtNormal,
  .pgTxtBold,
  .pgTxtBold_XL,
  .pgTxtBold_L,
  .pgTxtBoldNoTopSpace {
    font-size: $fontsize-17;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .pgTxtBold,
  .pgTxtBold_XL,
  .pgTxtBold_L,
  .pgTxtBoldNoTopSpace {
    font-size: $fontsize-20;
  }
}

ul.bulletPoints_un {
  list-style: none;
  margin: 0px 0px 30px 15px;
  font-size: $fontsize-19;

  .bp_indent_30 {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .bp_indent_60 {
    margin-left: 60px;
    margin-bottom: 25px;
  }

  .bp_indent_90 {
    margin-left: 90px;
    margin-bottom: 25px;
  }

  li.bp_squareOutline,
  li.bp_circleOutline,
  li.bp_circle,
  li.bp_circleFilled,
  li.bp_circleDouble {
    margin: 0px 0px 15px 30px;
    line-height: 30px;
  }

  li.bp_squareOutline::before,
  li.bp_circleOutline::before,
  li.bp_circle::before,
  li.bp_circleFilled::before,
  li.bp_circleDouble:before {
    color: $color-red;
    margin-left: -30px;
    padding-right: 15px;
  }

  li.bp_squareOutline::before {
    content: "\01f795";
  }

  li.bp_circleOutline::before {
    content: "\0025c9";
  }

  li.bp_circle::before {
    content: "\01f53e";
    font-size: $fontsize-16;
  }

  li.bp_circleFilled::before {
    content: "\0023fa";
  }

  li.bp_circleDouble::before {
    content: "\0025ce";
  }
}

@media ($smaller-than: $breakpoint-599) {
  ul.bulletPoints_un {
    font-size: $fontsize-17;
  }
}

//Inpage Component Styles

.inPgRightHeadResponsive {
  display: none;
  margin-top: 30px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-family: $font-times;
  font-weight: bold;
  font-size: $fontsize-26;
  color: $color-white;
  background: $color-red;
}

@media ($smaller-than: $breakpoint-899) {
  .inPgRightHeadResponsive {
    display: block;
  }
}

.setLoanAmtBlk {
  display: block;
  background: $color-white;
  padding: 30px 30px 40px 30px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);

  .form_dollariconRed,
  .form_percenticonRed {
    position: absolute;
    float: left;
    width: 40px;
    height: 38px;
    border-right: 1px dotted $color-red-lighter;
    margin: 1px 0 0 1px;
    border-radius: 3px 0 0 3px;
  }

  .form_dollariconRed {
    background: $dollarFormIconRed_SVG;
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .form_percenticonRed {
    background: $percentFormIconRed_SVG;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  input[type="text"] {
    display: block;
    font-family: $font-agaramond;
    font-size: $fontsize-20;
    color: $color-black;
    width: 100%;
    padding: 0px 10px 0px 47px;
    height: 40px;
    border: 1px solid $color-red;
    border-radius: 3px;
    background: $color-white;
    // background-image: $bgTexure;
    outline: 0;
  }

  select {
    width: 90px;
    font-family: $font-agaramond;
    font-size: $fontsize-20;
    color: $color-black;
    border: 1px solid $color-red;
    border-radius: 3px;
    outline: 0;
    padding: 0px 5px 0px 10px;
    height: 38px;
    background: $selectarrow_redIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blueIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
  }

  ul.loanAmtCalc {
    display: block;

    li.loanAmtCalc {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 15px;
    }

    li.loanAmtCalc:nth-child(odd) {
      width: 140px;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-blackbrown;
      line-height: 40px;
    }

    li.loanAmtCalc:nth-child(even) {
      width: calc(100% - 140px);
      width: -webkit-calc(100% - 140px);
    }
  }

  ul.loanAmtEMI {
    display: block;
    margin: 10px 0px 10px 0px;
    padding: 10px 0px;
    background-image: $bgTexure;

    li.loanAmtEMI {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2);
      width: -webkit-calc(100% / 2);
      line-height: 40px;
      font-family: $font-agaramondB;
      color: $color-blue-dark;
    }

    li.loanAmtEMI:first-child {
      text-align: right;
      font-size: $fontsize-22;
    }

    li.loanAmtEMI:last-child {
      text-align: left;
      font-size: $fontsize-24;
      padding-left: 10px;
    }
  }

  // .setLoanAmt {
  //   display: block;
  //   background: url(../img/SetLoanAmt.png) $color-white;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: top center;
  //   height: 290px;
  // }

  .apply_button {
    width: 100%;
    margin-top: 10px;
    padding: 15px 0px;
    font-family: $font-agaramondB;
    font-size: $fontsize-22;
    color: $color-white;
    text-transform: capitalize;
    border: 0px;
    background: $color-red;
    outline: none;
    border-radius: 5px;
  }

  .apply_button:hover {
    cursor: pointer;
    background: $color-red-light;
  }
}

.requestCallBackBlk {
  display: block;
  background: $color-white;
  padding: 30px 35px 40px 35px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);

  .requestCallBack {
    display: block;

    ::-webkit-input-placeholder {
      color: $color-lightblack;
    }

    :-ms-input-placeholder {
      color: $color-lightblack;
    }

    ::placeholder {
      color: $color-lightblack;
    }

    input[type="text"] {
      width: 100%;
      padding: 7px 0px 5px 0px;
      border: none;
      border-bottom: 3px solid $color-red-border;
      font-family: $font-agaramond;
      font-size: $fontsize-20;
      color: $color-black;
      outline: 0;
      border-radius: 0px;
      margin-bottom: 30px;
    }
  }

  .callBack_button {
    width: 100%;
    margin-top: -10px;
    padding: 15px 0px;
    font-family: $font-agaramondB;
    font-size: $fontsize-22;
    color: $color-white;
    text-transform: capitalize;
    border: 0px;
    background: $color-red;
    outline: none;
    border-radius: 5px;
  }

  .callBack_button:hover {
    cursor: pointer;
    background: $color-red-light;
  }
}

.contactInfoBlk {
  display: block;
  background: $color-white;
  padding: 30px 35px 10px 35px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);

  .contactInfo {
    display: block;

    .contInfoBlkDesc {
      display: block;
      font-family: $font-agaramond;
      font-size: $fontsize-18;
      text-align: justify;
      color: $color-black;
      margin-bottom: 40px;
    }

    ul.iconDesc {
      display: block;
      margin-bottom: 35px;

      li.iconDesc {
        display: inline-block;
        vertical-align: top;
        width: 40px;
      }

      li.iconDesc:last-child {
        width: calc(100% - 40px);
        width: -webkit-calc(100% - 40px);
      }
    }

    .contInfoLocIcon {
      display: block;
      width: 40px;
      height: 50px;
      background: $locationFormRedIcon_SVG;
      background-size: 24px;
      background-position: left center;
      background-repeat: no-repeat;
    }

    .contInfoPhIcon {
      display: block;
      width: 40px;
      height: 50px;
      background: $phFormRedIcon_SVG;
      background-size: 20px;
      background-position: left center;
      background-repeat: no-repeat;
    }

    .contInfoMailIcon {
      display: block;
      width: 40px;
      height: 50px;
      background: $mailFormRedIcon_SVG;
      background-size: 24px;
      background-position: left center;
      background-repeat: no-repeat;
    }

    .contInfoTxt {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-12;
      color: $color-lightblack-dark;
      text-transform: uppercase;
      margin: 2px 0px 5px 0px;
    }

    .contInfoTxtBold {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-22;
      color: $color-black;
    }
  }
}

/*Refinance*/
.refin_3col_prepare,
.refin_4col_fixedRate,
.refin_5col_lower,
.refin_5col_payoff,
.refin_6col_consolidate {
  display: block;
  margin: -10px 0px 20px 15px;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0px;
    padding: 0px;
    border: 1px solid $color-blackbrown;
    background-image: $bgTexure;
    color: $color-black;
    font-size: $fontsize-17;

    td {
      vertical-align: top;
      padding: 10px 10px;
      border-bottom: 1px solid $color-lightblack-dark;
    }

    tr:first-child td {
      background: $color-blackbrown;
      background-image: $bgTexure;
      font-family: $font-agaramondB;
      color: $color-white;
      border-bottom: 1px solid $color-blackbrown;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
}

.refin_3col_prepare {
  table {
    td:first-child,
    td:nth-child(2),
    td:last-child {
      width: calc(100% / 3);
      width: -webkit-calc(100% / 3);
    }
  }
}

.refin_4col_fixedRate {
  table {
    td:first-child {
      width: calc(100% - 550px);
      width: -webkit-calc(100% - 550px);
    }

    td:nth-child(2) {
      width: 140px;
    }

    td:nth-child(3),
    td:nth-child(4) {
      width: 180px;
    }
  }
}

.refin_5col_lower {
  table {
    td:first-child {
      width: auto;
    }

    td:nth-child(2),
    td:nth-child(5) {
      width: 150px;
    }

    td:nth-child(3),
    td:nth-child(4) {
      width: 100px;
    }
  }
}

.refin_5col_payoff {
  table {
    td:first-child {
      width: auto;
    }

    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      width: 120px;
    }
  }
}

.refin_6col_consolidate {
  table {
    td:first-child {
      width: auto;
    }

    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:last-child {
      width: 120px;
    }
  }
}

/*Profile Page Styles*/
.profile_pgBlk {
  display: block;
  margin-top: 25px;

  .accDetails {
    display: block;
    padding-bottom: 10px;
    padding: 0px;

    .acc_name {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-18;
      color: $color-black;
    }

    .memberBlock {
      display: block;
      margin: 4px 0 20px 0;

      .memberHead,
      .memberTxt {
        display: inline-block;
        text-align: top;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-15;
        color: $color-lightblack-dark;
        margin-right: 5px;
      }

      .memberTxt {
        font-weight: normal;
        font-family: $font-arial;
      }
    }

    .mailPhBlock {
      display: block;
      margin-bottom: 6px;

      .horBlk {
        display: block;
        margin-bottom: 10px;
      }

      .mailPh_txt {
        display: inline-block;
        vertical-align: top;
        font-family: $font-arial;
        font-size: $fontsize-15;
        color: $color-lightblack-dark;
        margin-left: 5px;
      }

      .mailIcon {
        display: inline-block;
        width: 27px;
        height: 13px;
        background: $emailIconBrown_SVG;
      }

      .phIcon {
        display: inline-block;
        width: 27px;
        height: 19px;
        background: $phIconBrown_SVG;
      }
    }

    .acc_btn_label {
      display: block;
      margin-top: 20px;

      .acc_btn {
        padding: 6px 15px;
        border: 1px solid $color-blue-lighter;
        border-radius: 3px;
        font-family: $font-arial;
        font-size: $fontsize-14;
        color: $color-blue;
        text-align: center;
        background: $color-offwhitelight;
        outline: none;
      }

      .acc_btn:hover {
        color: $color-blackbrown;
        cursor: pointer;
      }
    }
  }
}

/*About Us Page Styles*/
ul.aboutInpgContainer {
  display: block;
  margin: 70px 0px;

  li.aboutContainer {
    display: inline-block;
    vertical-align: top;
  }

  li.aboutContainer {
    width: 565px;
  }

  li.aboutContainer:last-child {
    width: calc(100% - 565px);
    width: -webkit-calc(100% - 565px);
    padding-left: 60px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.aboutContainer {
      width: 400px;
    }

    li.aboutContainer:last-child {
      width: calc(100% - 400px);
      width: -webkit-calc(100% - 400px);
      padding-left: 30px;
    }
  }

  @media ($smaller-than: $breakpoint-699) {
    li.aboutContainer,
    li.aboutContainer:last-child {
      display: block;
      width: 100%;
      padding: 0px;
    }
  }

  .aboutImgBlk {
    display: block;
    width: 100%;

    .aboutImage {
      width: 465px;
      height: 650px;
      background-image: url("../img/about.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media ($smaller-than: $breakpoint-1023) {
      .aboutImage {
        width: 300px;
        height: 450px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      .aboutImage {
        width: 85%;
        height: 450px;
      }
    }

    .expBlk {
      float: right;
      width: 200px;
      aspect-ratio: 1/1;
      background: $color-white;
      border: 1px solid $color-red-lightest;
      transform: translate(0%, 100%);
      padding: 12px;

      .expBlkContent {
        width: 100%;
        aspect-ratio: 1/1;
        background: $color-red;

        .txtExpNumber {
          display: block;
          text-align: center;
          padding-top: 30px;
          font-family: $font-agaramondB;
          font-size: $fontsize-80;
          color: $color-white;

          .symbolFont {
            font-size: $fontsize-6;
          }
        }

        .txtExp {
          display: block;
          text-align: center;
          font-family: $font-agaramondB;
          font-size: $fontsize-16;
          color: $color-white;
          text-transform: uppercase;
          margin-top: -15px;
        }

        @media ($smaller-than: $breakpoint-1023) {
          .txtExpNumber {
            padding-top: 20px;
            font-size: $fontsize-48;
          }

          .txtExp {
            font-size: $fontsize-14;
            margin-top: -5px;
          }
        }
      }
    }

    @media ($smaller-than: $breakpoint-1023) {
      .expBlk {
        width: 150px;
        aspect-ratio: 1/1;
      }
    }
  }

  .aboutContentBlk {
    display: block;

    .abt_headTxt {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-red;
      text-transform: uppercase;
      margin: 25px 0 15px 0;
    }

    .abt_heroTxt {
      display: block;
      font-family: $font-times;
      font-weight: bold;
      font-size: $fontsize-40;
      color: $color-blue-darker;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    .abt_pointHeadTxt {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-red;
      margin-bottom: 5px;
    }

    .abt_pointTxt {
      display: block;
      font-family: $font-agaramond;
      font-size: $fontsize-17;
      color: $color-blackbrown;
      margin-bottom: 24px;
    }

    @media ($smaller-than: $breakpoint-1023) {
      .abt_headTxt {
        font-size: $fontsize-18;
        margin: 0px 0 10px 0px;
      }

      .abt_heroTxt {
        font-size: $fontsize-24;
        margin-bottom: 20px;
      }

      .abt_pointHeadTxt {
        font-size: $fontsize-17;
        margin-bottom: 3px;
      }

      .abt_pointTxt {
        font-size: $fontsize-16;
        margin-bottom: 20px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      .abt_headTxt {
        margin: 30px 0 5px 0px;
      }
    }

    ul.abt_uspPoint {
      display: block;
      margin-top: 30px;

      li.abt_uspPoint {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        width: calc(100% / 2);
        width: -webkit-width (100% /2);
        font-family: $font-agaramondB;
        font-size: $fontsize-16;
        color: $color-blackbrown;
        margin-bottom: 10px;
      }

      li.abt_uspPoint::before {
        content: "\002b9a";
        margin-right: 10px;
        color: $color-blackbrown-light;
        font-size: $fontsize-20;
      }

      @media ($smaller-than: $breakpoint-1023) {
        li.abt_uspPoint {
          font-size: $fontsize-14;
        }
      }
    }

    .abt_button {
      border: 2px solid $color-red-lightest;
      padding: 24px 46px;
      font-family: $font-agaramondB;
      font-size: $fontsize-16;
      color: $color-blue-darker;
      text-transform: uppercase;
      margin-top: 23px;
      background: transparent;
      outline: none;
      transition-property: "background";
      transition-duration: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }

    .abt_button:hover {
      cursor: pointer;
      background: $color-blackbrown;
      border-color: $color-blackbrown;
      color: $color-white;
    }

    @media ($smaller-than: $breakpoint-999) {
      .abt_button {
        padding: 10px 22px;
        font-size: $fontsize-15;
      }
    }
  }
}

/*Loan Officers*/
.lo_pgBlk {
  display: block;
  margin-top: 25px;

  ul.filterLo {
    display: block;
    background: $color-white;
    padding: 20px 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 25px;

    li.filterLo {
      display: inline-block;
      vertical-align: top;
      width: 300px;
      margin-right: 10px;
    }

    li.filterLo:last-child {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-599) {
      li.filterLo {
        width: 225px;
      }
    }
  }

  select {
    width: 100%;
    font-family: $font-agaramond;
    font-size: $fontsize-16;
    color: $color-black;
    border: 1px solid $color-offblack;
    border-radius: 3px;
    outline: 0;
    padding: 0px 15px;
    height: 40px;
    background: $selectarrow_blackIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blueIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
  }

  .selectFilterLO > select {
    height: 50px;
    border-color: $color-lighterblack;
    font-family: $font-agaramondB;
    font-size: $fontsize-18;
    border-radius: 5px;
    background: $selectarrow_blackIcon_SVG $color-offwhitelight;
    background-position: right 3% center;
    background-size: 20px;
  }

  .selectFilterLO > select:hover {
    cursor: pointer;
    background: $selectarrow_redIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
    background-size: 20px;
  }

  .filterLo_but_blk {
    display: inline-block;
    vertical-align: top;
    width: 100px;

    .filterLo_but {
      display: block;
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 5px;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-white;
      outline: none;
      background: $color-red;
      text-align: center;
      margin: 0 auto;
    }

    .filterLo_but:hover {
      cursor: pointer;
    }
  }

  .loResults {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-30;
    color: $color-blue-dark;
    text-align: center;
    margin: 35px 0px 20px 0px;
  }

  ul.lo_display_blk {
    display: block;

    li.lo_display_blk {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 14px);
      width: -webkit-calc(100% / 3 - 14px);
      margin: 0px 21px 30px 0px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
      border-top: 2px solid $color-red-lighter;
      padding: 20px 20px 5px 20px;
      background: $color-white;
      min-height: 200px;
    }

    li.lo_display_blk:nth-child(3n + 0) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1023) {
      li.lo_display_blk:nth-child(even) {
        margin-right: 0px;
      }

      li.lo_display_blk {
        width: calc(100% / 2 - 14px);
        width: -webkit-calc(100% / 2 - 14px);
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      li.lo_display_blk {
        display: block;
        width: 100%;
        margin-right: 0px;
      }
    }
  }

  .loName {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-24;
    color: $color-red-dark;
    margin-bottom: 15px;
  }

  ul.lo_details {
    display: block;

    li.lo_details {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3 - 14px);
      width: -webkit-calc(100% / 3 - 14px);
      margin-bottom: 15px;
      font-size: $fontsize-17;
    }

    li.lo_details:nth-child(odd) {
      width: 105px;
      font-family: $font-agaramondB;
      color: $color-black;
    }

    li.lo_details:nth-child(even) {
      width: calc(100% - 105px);
      width: -webkit-calc(100% - 105px);
      font-family: $font-agaramond;
      color: $color-blackbrown;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    li.lo_details a {
      text-decoration: none;
      color: $color-blackbrown;
    }

    li.lo_details:hover {
      cursor: pointer;
    }
  }
}

/*Blogs*/
ul.blog_IIIcol {
  display: block;

  li.blog_IIIcol {
    display: inline-block;
    vertical-align: top;
    border-top: 0.25px solid $color-offblack-light;
    width: calc(100% / 3 - 17px);
    width: -webkit-calc(100%/3 - 17px);
    margin-right: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    padding-bottom: 40px;
  }

  li.blog_IIIcol:last-child {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-999) {
    li.blog_IIIcol,
    li.blog_IIIcol:last-child {
      display: block;
      width: 50%;
      margin: 0px auto 25px auto;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    li.blog_IIIcol,
    li.blog_IIIcol:last-child {
      width: 100%;
    }
  }

  .blogImg1 {
    display: block;
    background: url(../img/blog_img1.jpg);
    height: 250px;
    background-size: cover;
    margin-bottom: 15px;
  }

  .blogImg2 {
    display: block;
    background: url(../img/blog_img2.jpg) no-repeat;
    height: 250px;
    background-size: cover;
    margin-bottom: 15px;
  }

  .blogImg3 {
    display: block;
    background: url(../img/blog_img3.jpg) no-repeat;
    height: 250px;
    background-size: cover;
    margin-bottom: 15px;
  }

  .blogdateMonthBlk {
    position: relative;
    float: right;
    top: 185px;
    right: 20px;
    width: 70px;
    height: 80px;
    background: $color-red;
    text-align: center;

    .blogDate {
      font-family: $font-times;
      font-size: $fontsize-30;
      font-weight: bold;
      color: $color-white;
      margin: 12px 0px 0px 0px;
    }

    .blogMonth {
      font-family: $font-times;
      font-size: $fontsize-18;
      font-weight: bold;
      color: $color-white;
      text-transform: uppercase;
    }
  }

  .blogColHead {
    display: block;
    font-family: $font-times;
    font-size: $fontsize-24;
    font-weight: bold;
    color: $color-blackbrown;
    padding: 30px 35px 20px 35px;
  }

  .blogColDesc {
    display: block;
    font-family: $font-times;
    font-size: $fontsize-17;
    color: $color-lightblack;
    padding: 0px 35px 10px 35px;
    text-align: justify;
  }

  .blogReadMore {
    display: block;
    padding-left: 35px;
    text-decoration: none;
  }

  .blogReadMore .readMoreLink {
    font-family: $font-agaramondB;
    font-size: $fontsize-18;
    color: $color-red;
    text-transform: capitalize;
  }

  .blogReadMore .readMoreLink:hover {
    color: $color-blackbrown-lighter;
  }

  .blogReadMore .readMoreLink::after {
    content: "\0000bb";
    margin-left: 10px;
    font-size: $fontsize-30;
    color: $color-red;
  }

  @media ($smaller-than: $breakpoint-1200) {
    .blogColHead {
      font-size: $fontsize-20;
      padding-bottom: 10px;
    }

    .blogColDesc {
      font-size: $fontsize-15;
    }

    .blogReadMore .readMoreLink {
      font-size: $fontsize-16;
    }
  }
}

/*Statistics*/
ul.statisticsIIcol {
  display: block;
  margin: 30px 0px 0px 0px;
  padding: 0px;
  background: $color-white;

  li.statisticsIIcol {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 2 - 80px);
    width: -webkit-calc(100%/2 - 80px);
    margin: 50px 40px 30px 40px;
    min-height: 430px;
    text-align: center;
  }

  li.statisticsIIcol:first-child {
    background: url(../img/statistics.png) no-repeat;
    background-size: 90%;
    background-position: top center;
  }

  li.statisticsIIcol:last-child {
    background: url(../img/statistics1.png) no-repeat;
    background-size: 90%;
    margin-right: 0px;
    background-position: top center;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.statisticsIIcol {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}

/*FAQ*/
.faqBlk {
  display: block;
  background: $color-white;
  padding: 20px 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
  margin-bottom: 20px;

  ul.faqQ {
    display: block;

    li.faqQ {
      display: inline-block;
      vertical-align: top;
    }

    li.faqQ:first-child {
      width: calc(100% - 65px);
      width: -webkit-calc(100% - 65px);
      font-family: $font-agaramondB;
      font-size: $fontsize-21;
      color: $color-blue-dark;
      padding-top: 3px;
    }

    li.faqQ:last-child {
      width: 50px;
      text-align: right;
      margin-left: 15px;
    }
  }

  .faq_button,
  .faq_button_select {
    width: 28px;
    height: 28px;
    font-family: $font-agaramondB;
    font-size: $fontsize-20;
    color: $color-white;
    text-transform: capitalize;
    border: 0px;
    background: $arrowUp_whiteIcon_SVG $color-red;
    background-position: center center;
    background-size: 15px;
    outline: none;
    border-radius: 5px;
  }

  .faq_button_select {
    background: $arrowRight_whiteIcon_SVG $color-red;
    background-position: center center;
    background-size: 10px;
  }

  .faq_button:hover,
  .faq_button_select:hover {
    cursor: pointer;
  }

  .childBlock {
    display: block;
    margin-top: 10px;
    padding: 10px 0px 5px 0px;
    border-top: 1px solid $color-offwhite-dark;
    font-family: $font-agaramond;
    font-size: $fontsize-17;
    color: $color-black;
  }
}

/*Mortgage Rates*/
.mortgageRateBlk {
  display: block;

  .mr_heading {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-30;
    color: $color-red-dark;
    text-align: center;
  }

  .mr_noteTxt {
    display: block;
    font-family: $font-agaramond;
    font-size: $fontsize-16;
    color: $color-lightblack;
    margin: -3px 0px 15px 0px;
    text-align: center;
  }

  .rateTip {
    display: inline-block;
    vertical-align: top;
    width: 15px;
    height: 15px;
    background: $infoFormIcon_SVG;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .rateTip:hover {
    cursor: pointer;
  }

  ul.rateBlock {
    display: block;
    background: $color-white;
    padding: 15px 20px 15px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    margin-bottom: 25px;

    li.rateBlock {
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
    }

    li.rateBlock:first-child {
      width: calc(100% - 330px);
      width: -webkit-calc(100% - 330px);
    }

    li.rateBlock:nth-child(2),
    li.rateBlock:nth-child(3) {
      width: 85px;
    }

    li.rateBlock:nth-child(4) {
      width: 100px;
      margin-right: 0px;
      padding-top: 10px;
    }

    .rateHead,
    .rateResult {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-blackbrown;
    }

    .rateHead {
      margin-bottom: 2px;
      color: $color-lighterblack;
      font-size: $fontsize-14;
    }

    .rateResult {
      font-size: $fontsize-26;

      .symbol {
        position: relative;
        font-size: $fontsize-9;
        color: $color-lightblack;
        left: 3px;
      }
    }

    .rate_button {
      padding: 8px 12px 5px 12px;
      font-family: $font-agaramondB;
      font-size: $fontsize-17;
      color: $color-white;
      text-transform: capitalize;
      border: 0px;
      background: $color-red-light;
      outline: none;
      border-radius: 5px;
    }

    .rate_button:hover {
      cursor: pointer;
      background: $color-red-light;
    }
  }
}

/*Loan Process*/
.loanProcessBlk {
  display: block;
  margin-top: 30px;

  .loanProcessHead {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-32;
    color: $color-black;
    text-align: center;
    margin-bottom: 40px;
  }

  ul.lp_headblk {
    display: block;

    li.lp_headblk {
      display: inline-block;
      vertical-align: top;
      height: 40px;
    }

    li.lp_headblk:first-child,
    li.lp_headblk:last-child {
      width: 80px;
    }

    li.lp_headblk:nth-child(2) {
      width: calc(100% - 160px);
      width: -webkit-calc(100% - 160px);
      text-align: center;
      border-radius: 15px 15px 0px 0px;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-white;
      background: $color-red;
      line-height: 45px;
    }

    .lp_leftCurveBlk {
      width: 20px;
      height: 20px;
      position: relative;
      float: right;
      top: 20px;
      background: $color-red;

      .lp_leftCurve {
        width: 20px;
        height: 20px;
        background-image: $bgTexure;
        background: $color-offwhitelight;
        border-bottom-right-radius: 15px;
      }
    }

    .lp_rightCurveBlk {
      width: 20px;
      height: 20px;
      position: relative;
      float: left;
      top: 20px;
      background: $color-red;

      .lp_rightCurve {
        width: 20px;
        height: 20px;
        background-image: $bgTexure;
        background: $color-offwhitelight;
        border-bottom-left-radius: 15px;
      }
    }
  }
  .lpContentBox {
    display: block;
    background: $color-white;
    border-top: 1px solid $color-red;
    padding: 25px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;

    .lpContent {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-black;
      text-align: center;
    }

    .lpBtnBlk {
      display: block;
      text-align: center;
      margin: 30px 0px 10px 0px;

      .lpButton {
        padding: 8px 15px;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-white;
        text-transform: capitalize;
        border: 0px;
        background: $color-red;
        outline: none;
        border-radius: 7px;
      }

      .lpButton:hover {
        cursor: pointer;
        background: $color-red-light;
      }
    }
  }
}

/*Reviews*/
.reviewBlk {
  display: block;

  ul.IIcol_Review {
    display: block;
    margin: 30px 0px 0px 0px;
    padding: 0px;

    li.IIcol_Review {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 13px);
      width: -webkit-calc(100%/2 - 13px);
      margin: 0px 25px 25px 0px;
    }

    li.IIcol_Review:nth-child(even) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1023) {
      li.IIcol_Review {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  .reviewContentBlkLeft,
  .reviewContentBlkRight {
    display: block;
    border-radius: 25px 0px 0px 0px;
    background: $color-offwhitelight;
    background-image: $bgTexure;
    // border: 1px solid $color-lighterwhite;

    ul.reviewPhotoContent {
      display: block;
      padding: 20px;

      li.reviewPhotoContentLeft,
      li.reviewPhotoContentRight {
        display: inline-block;
        vertical-align: top;
      }

      li.reviewPhotoContentLeft:first-child {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        border-radius: 25px;
        background: $color-red;
        font-family: $font-agaramondB;
        font-size: $fontsize-30;
        color: $color-white;
        text-align: center;
        line-height: 100px;
      }

      li.reviewPhotoContentLeft:last-child {
        width: calc(100% - 120px);
        width: -webkit-calc(100% - 120px);
      }

      li.reviewPhotoContentRight:first-child {
        width: calc(100% - 120px);
        width: -webkit-calc(100% - 120px);
        margin-right: 20px;
      }

      li.reviewPhotoContentRight:last-child {
        width: 100px;
        height: 100px;
        border-radius: 25px;
        background: $color-red;
        font-family: $font-agaramondB;
        font-size: $fontsize-30;
        color: $color-white;
        text-align: center;
        line-height: 100px;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.reviewPhotoContentLeft:first-child,
        li.reviewPhotoContentRight:last-child {
          display: none;
        }

        li.reviewPhotoContentLeft:last-child,
        li.reviewPhotoContentRight:first-child {
          display: block;
          width: 100%;
        }
      }

      .reviewerPhoto {
        width: 100px;
        height: 100px;
        border-radius: 25px;
        background: $color-white;
        background-image: $bgTexure;
      }
      .reviewerPhoto img {
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        min-width: 100%;
        border-radius: 25px;
      }

      .reviewerName {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-24;
        color: $color-red-dark;
        margin-bottom: 15px;
        border-bottom: 1px solid $color-white;
        padding-bottom: 2px;
        text-transform: uppercase;
      }

      ul.ratingDateBlk {
        display: block;
        margin-bottom: 25px;

        li.ratingDateBlk {
          display: inline-block;
          vertical-align: top;
          width: calc(100% / 2 - 13px);
          width: -webkit-calc(100%/2 - 13px);
          margin-right: 25px;
        }

        li.ratingDateBlk:nth-child(odd) {
          margin-top: -3px;
        }

        li.ratingDateBlk:nth-child(even) {
          margin-right: 0px;
          text-align: right;
          font-family: $font-agaramond;
          font-size: $fontsize-16;
          color: $color-blackbrown;
        }

        .rating_sprite_red {
          height: 22px;
          line-height: 0px;
          overflow: hidden;
          text-indent: -999em;
          width: 127px;
          background-image: $starRating_SVG;
        }
        .rating_sprite_red_bg {
          float: left;
          background-image: $starRatingBg_SVG;
          background-position: 0 100%;
          height: 22px;
        }
      }

      .reviewDesc {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-black;
        margin-bottom: 10px;
      }

      .reviewContent {
        display: block;
        font-family: $font-agaramond;
        font-size: $fontsize-17;
        color: $color-black;
        margin-bottom: 10px;
      }
    }
  }

  .reviewContentBlkRight {
    border-radius: 0px 25px 0px 0px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    .reviewContentBlkLeft {
      background: none;
      background-image: linear-gradient(
        to right,
        rgba(219, 219, 219, 1) 50%,
        rgba(0, 0, 0, 0)
      );
    }

    .reviewContentBlkRight {
      background: none;
      background-image: linear-gradient(
        to left,
        rgba(219, 219, 219, 1) 50%,
        rgba(0, 0, 0, 0)
      );
    }
  }

  ul.reviewCurveArrowLeft,
  ul.reviewCurveArrowRight {
    display: block;
    height: 40px;
    margin-bottom: 35px;

    li.reviewCurveArrowLeft,
    li.reviewCurveArrowRight {
      display: inline-block;
      vertical-align: top;
    }

    li.reviewCurveArrowLeft:nth-child(1) {
      width: 40px;
      background: $color-lighterwhite;
    }

    li.reviewCurveArrowLeft:nth-child(2) {
      width: calc(100% - 40px);
      width: -webkit-calc(100% - 40px);
    }

    li.reviewCurveArrowRight:nth-child(1) {
      width: calc(100% - 40px);
      width: -webkit-calc(100% - 40px);
    }

    li.reviewCurveArrowRight:nth-child(2) {
      width: 40px;
      background: $color-lighterwhite;
    }

    .reviewLeftCurve {
      width: 40px;
      height: 40px;
      border-top-left-radius: 100%;
      background: $color-white;
      background-image: $bgTexure;
    }

    .reviewRightCurve {
      width: 40px;
      height: 40px;
      border-top-right-radius: 100%;
      background: $color-white;
      background-image: $bgTexure;
    }
  }

  @media ($bigger-than: $breakpoint-1023) {
    ul.reviewCurveArrowLeft,
    ul.reviewCurveArrowRight {
      display: none;
    }
  }
}

/*Branch Location*/
.branchLocationBlk {
  display: block;
  margin-top: 30px;

  .loanProcessHead {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-32;
    color: $color-black;
    text-align: center;
    margin-bottom: 40px;
  }

  ul.lp_headblk {
    display: block;

    li.lp_headblk {
      display: inline-block;
      vertical-align: top;
      height: 40px;
    }

    li.lp_headblk:first-child,
    li.lp_headblk:last-child {
      width: 80px;
    }

    li.lp_headblk:nth-child(2) {
      width: calc(100% - 160px);
      width: -webkit-calc(100% - 160px);
      text-align: center;
      border-radius: 15px 15px 0px 0px;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-white;
      background: $color-red;
      line-height: 45px;
    }

    .lp_leftCurveBlk {
      width: 20px;
      height: 20px;
      position: relative;
      float: right;
      top: 20px;
      background: $color-red;

      .lp_leftCurve {
        width: 20px;
        height: 20px;
        background-image: $bgTexure;
        background: $color-offwhitelight;
        border-bottom-right-radius: 15px;
      }
    }

    .lp_rightCurveBlk {
      width: 20px;
      height: 20px;
      position: relative;
      float: left;
      top: 20px;
      background: $color-red;

      .lp_rightCurve {
        width: 20px;
        height: 20px;
        background-image: $bgTexure;
        background: $color-offwhitelight;
        border-bottom-left-radius: 15px;
      }
    }
  }
  .blContentBox {
    display: block;
    background: $color-white;
    border-top: 1px solid $color-red;
    padding: 25px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;

    .bl_head {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-red-dark;
      font-weight: bold;
      margin-bottom: 10px;
      text-decoration: underline;
    }

    .bl_city {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-16;
      color: $color-blue-dark;
      font-weight: bold;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .bl_content {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-16;
      color: $color-lightblack-dark;

      .state {
        display: block;
      }

      ul.locIconDirection {
        display: block;
        margin-top: 10px;

        li.locIconDirection {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 22px);
          width: -webkit-calc(100% - 22px);
          font-weight: normal;
        }
        li.locIconDirection:nth-child(1) {
          width: 22px;
          aspect-ratio: 1/1;
          background: $locationIcon_SVG;
          background-size: 14px;
          background-position: top left;
        }
        li.locIconDirection a {
          color: $color-blue;
        }
        li.locIconDirection a:hover {
          color: $color-lightblack-dark;
        }
      }
    }

    .lpBtnBlk {
      display: block;
      text-align: center;
      margin: 30px 0px 10px 0px;

      .lpButton {
        padding: 8px 15px;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-white;
        text-transform: capitalize;
        border: 0px;
        background: $color-red;
        outline: none;
        border-radius: 7px;
      }

      .lpButton:hover {
        cursor: pointer;
        background: $color-red-light;
      }
    }
  }
}

/*Consent LO */
.consentLO_pgBlk {
  display: block;
  margin: 25px 0px;
  padding: 20px 25px 30px 25px;
  background: $color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);

  .consentLO_PgHead_Blk {
    display: block;
    margin: 0 0 -10px 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid $color-offwhite-dark;

    .consentLO_PgHead {
      display: inline-block;
      vertical-align: top;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-30;
      color: $color-blue-navi-dark;
    }

    .consentSelectLoan {
      display: inline-block;
      vertical-align: top;
      width: 200px;
      margin-left: 10px;

      select {
        width: 100%;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-blackbrown-light;
        border: 1px solid $color-blue;
        border-radius: 3px;
        outline: 0;
        padding: 0px 10px;
        height: 35px;
        background: $selectarrow_blackIcon_SVG $color-offwhitelight;
        background-position: right 3% center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      select:hover {
        cursor: pointer;
        background: $selectarrow_blueIcon_SVG $color-offwhitelight;
        background-position: right 3% center;
      }
    }
  }

  .consentLO_TxtHead {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-blackbrown;
    margin-bottom: 5px;
  }

  .consentLO_Txt {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-14;
    color: $color-blackbrown-light;
    margin-top: 20px;
  }

  .consent_radioLabel {
    display: block;
    padding-top: 10px;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-black;

    input[type="radio"] + label:before {
      background: $color-white;
      width: 17px;
      height: 17px;
      border-color: $color-blackbrown;
    }

    input[type="radio"]:checked + label:before {
      background: $color-blue;
      border-color: $color-blue;
      color: $color-white;
      line-height: 18px;
      font-size: $fontsize-28;
    }
  }

  .consentButtonLabel {
    display: block;
    margin-top: 15px;

    .consentButton {
      display: inline-block;
      vertical-align: top;
      margin: 0px 3px;
      padding: 6px 10px;
      border-radius: 3px;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-white;
      outline: none;
      border: 0px;
      background: $color-blue;
      background-image: $bgTexure;
      text-align: center;
    }

    .consentButton:hover {
      cursor: pointer;
      background: $color-black;
    }
  }
}

/*LO Landing*/
.loLandingBlk {
  display: block;
  margin-top: 46px;

  .loLandingRedBox {
    position: absolute;
    height: 350px;
    width: 225px;
    background: $color-red;
    z-index: 2;
    margin-top: -25px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    .loLandingRedBox {
      display: none;
    }
  }

  ul.loLandingNamePhotoBlk {
    display: block;
    position: relative;
    z-index: 3;
    margin: 25px 0px 0px 0px;

    li.loLandingPhotoBlk {
      display: inline-block;
      vertical-align: top;
      height: 300px;
      width: 300px;
      margin-left: 80px;
      border-radius: 50%;
      border: 16px solid transparent;
      background: $color-white;
      background-image: $bgTexure;

      img {
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        min-width: 100%;
        border-radius: 50%;
      }
    }

    li.loLandingAboutTxt {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 655px);
      width: -webkit-calc(100% - 655px);
      padding: 40px 0px 0px 20px;

      .loLandingName {
        display: inline-block;
        padding: 8px 12px 0px 12px;
        background: $color-red;
        font-family: $font-agaramondB;
        font-size: $fontsize-38;
        color: $color-white;
        text-transform: uppercase;
      }

      .loLandingPosition {
        display: block;
        margin: 10px 0px 25px 14px;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-blackbrown;
        text-transform: uppercase;
      }

      .loLandingAbout {
        display: block;
        margin: 0px 0px 10px 14px;
        font-family: $font-agaramond;
        font-size: $fontsize-18;
        color: $color-blackbrown-light;
        text-align: justify;
      }

      .loLandingBtnLabel {
        display: block;
        margin: 25px 0px 0px 15px;

        .loLandingButton {
          display: inline-block;
          vertical-align: top;
          margin: 0px 8px 0px 0px;
          padding: 10px 30px;
          border-radius: 50px;
          font-family: $font-agaramondB;
          font-size: $fontsize-18;
          color: $color-white;
          outline: none;
          border: 0px;
          background: $color-red;
          background-image: $bgTexure;
          text-align: center;
        }

        .loLandingButton:hover {
          cursor: pointer;
          background: $color-red-dark;
        }
      }
    }

    li.loLandingContactDetails {
      display: inline-block;
      vertical-align: top;
      width: 275px;
      padding: 50px 0px 0px 40px;

      .loLandingContact {
        display: block;

        .loContactHead {
          display: block;
          border: 1px solid $color-red;
          padding: 10px 0px 5px 10px;
          font-family: $font-agaramondB;
          font-size: $fontsize-22;
          color: $color-red-dark;
          background-image: $bgTexure;
          border-radius: 3px;
        }

        ul.contactIconTxtBlk {
          display: block;
          margin: 20px 0px 0px 10px;

          li.contactIconTxtBlk {
            display: inline-block;
            vertical-align: top;
            width: 25px;
            margin: 10px 0px 10px 0px;
          }

          li.contactIconTxtBlk:nth-child(even) {
            width: calc(100% - 25px);
            width: -webkit-calc(100% - 25px);
            padding-left: 10px;
          }

          .loContactTextHead {
            display: block;
            font-family: $font-agaramond;
            font-size: $fontsize-15;
            font-weight: bold;
            color: $color-black;
            text-transform: uppercase;
            margin-bottom: 2px;
          }

          .loContactText {
            display: block;
            font-family: $font-agaramond;
            font-size: $fontsize-17;
            color: $color-blackbrown;
            margin-bottom: 3px;
          }
        }

        .loPhoneIcon,
        .loEmailIcon {
          display: block;
          width: 25px;
          height: 25px;
          margin-top: 7px;
        }

        .loPhoneIcon {
          background: $mobileIconRed_SVG;
          background-size: 25px;
          background-position: top left;
        }

        .loEmailIcon {
          background: $emailIconRed_SVG;
          background-size: 25px;
          background-position: top left;
        }
      }
    }

    @media ($smaller-than: $breakpoint-1023) {
      li.loLandingPhotoBlk,
      li.loLandingAboutTxt,
      li.loLandingContactDetails {
        display: block;
        width: 100%;
        padding-left: 0px;
      }

      li.loLandingPhotoBlk {
        width: 300px;
        margin-left: 0px;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      li.loLandingPhotoBlk {
        width: 250px;
        height: 250px;
      }
      li.loLandingAboutTxt {
        padding-top: 10px;

        .loLandingName {
          font-size: $fontsize-28;
        }

        .loLandingPosition {
          font-size: $fontsize-16;
          margin: 5px 0px 15px 14px;
        }

        .loLandingAbout {
          font-size: $fontsize-16;
        }
        .loLandingBtnLabel {
          margin: 20px 0px 0px 15px;

          .loLandingButton {
            padding: 8px 25px;
            border-radius: 50px;
            font-family: $font-agaramondB;
            font-size: $fontsize-16;
            color: $color-white;
            outline: none;
            border: 0px;
            background: $color-red;
            background-image: $bgTexure;
            text-align: center;
          }

          .loLandingButton:hover {
            cursor: pointer;
            background: $color-red-dark;
          }
        }
      }
      li.loLandingContactDetails {
        padding-top: 30px;
      }
    }
    @media ($smaller-than: $breakpoint-399) {
      li.loLandingPhotoBlk {
        margin: 0px auto;
      }
      li.loLandingAboutTxt {
        display: block;
        text-align: center;
      }
    }
  }
}

/*Subscription Preference */
.subscription_pgBlk {
  display: block;
  margin: 25px 0px;
  padding: 20px 25px 30px 25px;
  background: $color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);

  .subscription_PgHead {
    display: block;
    margin: 0 0 -10px 0;
    padding: 0 0 7px 0;
    border-bottom: 1px solid $color-offwhite-dark;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-30;
    color: $color-blue-navi-dark;
  }

  .subscription_PgDescHead {
    display: block;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-15;
    color: $color-blackbrown;
    margin-top: 24px;
  }

  ul.subscriptionIIcol {
    display: block;
    margin: 20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;

    li.subscriptionIIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 13px);
      width: -webkit-calc(100%/2 - 10px);
      margin: 0px 20px 20px 0px;
    }

    li.subscriptionIIcol:nth-child(even) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.subscriptionIIcol {
        display: block;
        width: 100%;
        margin: 0px 0px 15px 0px;
      }
    }
  }

  .subscriptionCheckboxLabel {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-blackbrown;
    border: 1px solid $color-offblack-light;
    padding: 15px;
    border-radius: 10px;
    background: $bgTexure;
  }

  input[type="checkbox"] {
    display: none;
    cursor: pointer;
  }

  input[type="checkbox"]:not([disabled]) + label {
    cursor: pointer;
  }

  input[type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin: -3px 7px 3px 0;
    border-radius: 3px;
    border: 1px solid $color-offblack;
    background: $color-white;
  }

  input[type="checkbox"]:checked + label:before {
    content: "\2714";
    color: $color-black;
    font-family: $font-arial;
    font-size: $fontsize-16;
    text-align: center;
    line-height: 20px;
  }

  .subscriptionBtnLabel {
    display: block;
    padding: 10px 0px 0px 0px;
    border-top: 5px solid $color-offwhite;

    .subscriptionBtn {
      display: inline-block;
      vertical-align: middle;
      padding: 0px 29px;
      height: 45px;
      margin-right: 8px;
      border: 0px solid $color-blue;
      border-radius: 5px;
      font-family: $font-arial;
      font-size: $fontsize-17;
      color: $color-white;
      outline: none;
      background: $color-blue;
      text-align: center;
    }

    .subscriptionBtn:hover {
      background: $color-blue-navi;
      cursor: pointer;
    }
  }
}
